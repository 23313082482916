import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
  validate,
} from './constant';
import { Component } from './component';
import { action, cancelAction } from './action';
import { USER_ROLE } from '../../data/auth/constant';
import { CONSULTATION_USER_MODULE_NAME } from '../consultation-user-item';
import { FormikValues, useFormik } from 'formik';
import { checkTimeDifference } from '../../lib/util/checkTimeDifference';

export const Container: React.FC<{
  paymentId: string;
  price: number;
  duration: number;
  isPaid: boolean;
  isCancel: boolean;
  role: USER_ROLE;
  isAfterConsultation: boolean;
  isChangeTimeDisabled: boolean;
}> = ({
  paymentId,
  price,
  duration,
  isPaid,
  isCancel,
  role,
  isAfterConsultation,
  isChangeTimeDisabled,
}) => {
  const queryClient = useQueryClient();

  const getLink = useMutation(() => action(paymentId), {
    onSuccess: (data) => {
      const url = data?.data;
      if (url) {
        window.open(url, '_blank');
      }
    },
  });

  const cancelOrder = useMutation(
    (cancelInfo: string) => cancelAction(paymentId, cancelInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CONSULTATION_USER_MODULE_NAME);
      },
    },
  );

  const handlePay = () => getLink.mutate();
  const handleCancel = (cancelInfo: string) => cancelOrder.mutate(cancelInfo);

  const isLoading = () => {
    if (getLink.isLoading) {
      return true;
    }

    if (cancelOrder.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (getLink.isError && !getLink.isLoading && getErrorMessage()) {
      return true;
    }
    if (cancelOrder.isError && !cancelOrder.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = getLink.error as ACTION_ERROR_INTER;
    const errorCancel: ACTION_ERROR_INTER =
      cancelOrder.error as ACTION_ERROR_INTER;

    if (errorCancel) {
      return errorCancel.message;
    }

    if (error) {
      return error.message;
    }
  };

  const initialValues = {
    [FORM_VALUE_ENUM.CANCELLATION_REASON]: '',
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: () => {},
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (isAfterConsultation) {
      return true;
    }

    if (isLoading()) {
      return true;
    }

    if (isCancel) {
      return true;
    }
  };

  const isCancelDisabled = !formik.isValid || !formik.dirty || isLoading();

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  return (
    <Component
      role={role}
      isSuccess={cancelOrder.isSuccess}
      handleCancel={handleCancel}
      handlePay={handlePay}
      isPaid={isPaid}
      isCancel={isCancel}
      price={price}
      duration={duration}
      isLoading={isLoading()}
      isError={isError()}
      errorMessage={getErrorMessage()}
      formik={formik}
      getFieldValue={getFieldValue}
      getFieldError={getFieldError}
      isFieldError={isFieldError}
      isSubmitDisabled={isSubmitDisabled()}
      isChangeTimeDisabled={isChangeTimeDisabled}
      isCancelDisabled={isCancelDisabled}
    />
  );
};
