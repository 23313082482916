import React from 'react';
import { FormikValues } from 'formik';
import { FORM_VALUE_ENUM } from './constant';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import sendIcon from '../../asset/svg/button/invite.svg';
import { FieldPasswordElem } from '../../common/field-password';
import { SelectElem } from '../../common/select';
import { USER_ROLE_OPTION_LIST } from '../../data/user/constant';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { ReactComponent as AddIconLarge } from '../../asset/svg/common/add.svg';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
  formVisible: boolean;
  setFormVisible: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setFieldValue,
  setValue,
  formVisible,
  setFormVisible,
}) => {
  return (
    <>
      {formVisible ? (
        <FormElem onSubmit={formik.handleSubmit}>
          {isLoading && <LoaderElem />}
          <ContentContainerElem>
            <GridElem spacing={5}>
              <TitleContainer>
                <TextElem
                  tid="USER.CREATE.TITLE"
                  type="semi-bold"
                  size="main"
                />
                <CloseIcon
                  onClick={() => setFormVisible(false)}
                  style={{ cursor: 'pointer' }}
                />
              </TitleContainer>
              <DoubleContainerElem>
                <FieldTextElem
                  name={FORM_VALUE_ENUM.EMAIL}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.CREATE.FORM.EMAIL"
                  value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
                  error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
                />
                <SelectElem
                  name={FORM_VALUE_ENUM.ROLE}
                  onChange={setFieldValue}
                  options={USER_ROLE_OPTION_LIST}
                  title="USER.CREATE.FORM.ROLE"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.ROLE)}
                  error={isFieldError(FORM_VALUE_ENUM.ROLE)}
                  value={setValue(USER_ROLE_OPTION_LIST, FORM_VALUE_ENUM.ROLE)}
                />
              </DoubleContainerElem>
              <DoubleContainerElem>
                <FieldPasswordElem
                  name={FORM_VALUE_ENUM.PASSWORD}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.CREATE.FORM.PASSWORD"
                  value={getFieldValue(FORM_VALUE_ENUM.PASSWORD)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.PASSWORD)}
                  error={isFieldError(FORM_VALUE_ENUM.PASSWORD)}
                />
                <FieldTextElem
                  name={FORM_VALUE_ENUM.NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.CREATE.FORM.NAME"
                  value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.NAME)}
                />
              </DoubleContainerElem>
              <DoubleContainerElem>
                <ButtonElem
                  type="submit"
                  tid="USER.CREATE.FORM.CREATE"
                  disabled={isSubmitDisabled()}
                />
              </DoubleContainerElem>
            </GridElem>
            {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
          </ContentContainerElem>
        </FormElem>
      ) : (
        <AddContainer onClick={() => setFormVisible(true)}>
          <TextElem
            tid="USER.CREATE.ADD"
            type="medium"
            size="semiSmall"
            color="textPrimary"
          />
          <AddIconLarge />
        </AddContainer>
      )}
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddContainer = styled(TitleContainer)`
  height: 56px;
  padding: ${Spacing(0)} ${Spacing(6)};
  cursor: pointer;
  transition: all 0.2s;

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
`;
