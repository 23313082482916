import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { useHistory } from 'react-router';
import { FormElem } from '../../common/form';
import { Spacing } from '../../theme';
import { FormikValues } from 'formik';
import { LoaderElem } from '../../common/loader';
import {
  citiesOptions,
  dateOptions,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
  petsOptions,
  regionsOptions,
  servicesOptions,
  timeOptions,
} from './constant';
import { SelectElem } from '../../common/select';
import { ButtonElem } from '../../common/button';
import { timeOption } from '../../data/time/constant';
import { CITY_OPTION_LIST } from '../psycho-filter/constant';
import { ANIMAL_TYPE_OPTION_LIST } from '../profile-psycho-update-specialization/constant';
import { DatePickerElem } from '../../common/date-picker';
import dayjs from 'dayjs';
import moment from 'moment';
import { convertDate } from './convert';
import { CATEGORY_OPTION_LIST } from '../profile-psycho-create-category/constant';
import { DateField } from '../../common/field-date';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: any;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  setFieldValue,
  setValue,
}) => {
  // const convertDate = (strDate: string) => {
  //   if (strDate == '' || strDate == null || strDate == undefined) {
  //     return null;
  //   }
  //   const date = new Date(strDate);
  //   return dayjs(date).format('DD.MM');
  // };

  // const history = useHistory();

  // const handleRedirect = (id: number) => {
  //   console.log(id);
  //   history.push(`/specialist-list`);
  // };
  // ?categoryList=${id}

  // const handleSelectChange = (name: FORM_VALUE_TYPE, values: any) => {
  //   console.log(values, name);
  //   formik.setFieldValue(name, [...values]);
  // };

  // (selectedOption: any) => {
  //   console.log(selectedOption);
  //   formik.setFieldValue(name, selectedOption.value);
  // };

  return (
    <FormWrapper>
      <Form onSubmit={formik.handleSubmit}>
        {isLoading && <LoaderElem />}
        <FormContainer>
          <TitleContainer>
            <TextStyled
              size="logo"
              color="textPrimary"
              type="semi-bold"
              tid="LANDING.HOME_CATALOG.CATALOG_TEXT.TITLE"
              fontFamily="Raleway"
            />

            <TextStyledSubttile
              oneLine
              size="default"
              color="textSecondary"
              type="medium"
              tid="LANDING.HOME_CATALOG.CATALOG_TEXT.SUBTITLE"
              fontFamily="Raleway"
            />
          </TitleContainer>

          <GridContainer spacing={3}>
            <Select
              name={FORM_VALUE_ENUM.NAME_PET}
              onChange={setFieldValue}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.NAME"
              value={setValue(
                ANIMAL_TYPE_OPTION_LIST,
                FORM_VALUE_ENUM.NAME_PET,
              )}
              errorMessage={getFieldError(FORM_VALUE_ENUM.NAME_PET)}
              error={isFieldError(FORM_VALUE_ENUM.NAME_PET)}
              options={ANIMAL_TYPE_OPTION_LIST}
            />

            <Select
              name={FORM_VALUE_ENUM.SERVICE}
              onChange={setFieldValue}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.SERVICE"
              value={setValue(CATEGORY_OPTION_LIST, FORM_VALUE_ENUM.SERVICE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.SERVICE)}
              error={isFieldError(FORM_VALUE_ENUM.SERVICE)}
              options={CATEGORY_OPTION_LIST}
            />

            <Select
              name={FORM_VALUE_ENUM.CITY}
              onChange={setFieldValue}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.CITY"
              value={setValue(CITY_OPTION_LIST, FORM_VALUE_ENUM.CITY)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.CITY)}
              error={isFieldError(FORM_VALUE_ENUM.CITY)}
              options={CITY_OPTION_LIST}
            />

            <Select
              name={FORM_VALUE_ENUM.REGION}
              onChange={setFieldValue}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.REGION"
              value={setValue(regionsOptions, FORM_VALUE_ENUM.REGION)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.REGION)}
              error={isFieldError(FORM_VALUE_ENUM.REGION)}
              options={regionsOptions}
            />

            <Select
              name={FORM_VALUE_ENUM.TIME}
              onChange={setFieldValue}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.TIME"
              value={setValue(timeOption, FORM_VALUE_ENUM.TIME)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
              error={isFieldError(FORM_VALUE_ENUM.TIME)}
              options={timeOption}
            />

            {/* <Select
              name={FORM_VALUE_ENUM.DATE}
              onChange={handleSelectChange(FORM_VALUE_ENUM.DATE)}
              onBlur={formik.handleBlur}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.DATE"
              value={formik.values[FORM_VALUE_ENUM.DATE]}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
              error={isFieldError(FORM_VALUE_ENUM.DATE)}
              options={dateOptions}
            /> */}

            <DateField
              selectedDate={getFieldValue(FORM_VALUE_ENUM.DATE)}
              setSelectedDate={(e: string) =>
                setFieldValue(FORM_VALUE_ENUM.DATE, { value: e })
              }
              title="LANDING.HOME_CATALOG.SELECT_TEXT.DATE"
              placeholder="CARETAKER_LIST.MODAL.DATE"
            />
            {/* <StyledDatePicker
              name={FORM_VALUE_ENUM.DATE}
              title="LANDING.HOME_CATALOG.SELECT_TEXT.DATE"
              handleChange={(e: any) => {
                return formik.setFieldValue(FORM_VALUE_ENUM.DATE, e);
              }}
              initialValue={convertDate(getFieldValue(FORM_VALUE_ENUM.DATE))}
            /> */}
          </GridContainer>

          <ButtonContainer>
            <ButtonStyled
              // onClick={handleRedirect}
              disabled={isSubmitDisabled}
              type="submit"
              fill="solid"
              tid="LANDING.HOME_CATALOG.BUTTON_TEXT.TEXT"
              sizeText="main"
              textType="bold"
            />
          </ButtonContainer>
        </FormContainer>
      </Form>
    </FormWrapper>
  );
};

const StyledDatePicker = styled(DatePickerElem)`
  height: 54px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  .ant-picker-input > input {
    max-width: none;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  }
  &:hover {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]} !important;
  }
  :hover > .ant-picker-outline {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]} !important;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${Spacing(20)};
  width: 100%;
  min-height: 100vh;
`;

const Form = styled(FormElem)`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-size: cover;
  padding: 0px ${Spacing(49)} ${Spacing(49)};
  margin: 0;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  max-width: ${Spacing(350)};
  display: grid;
  justify-content: center;
  align-items: center;
  background: url('./assets/img/landing/background-form.jpeg') no-repeat center
    center;

  @media (max-width: 730px) {
    padding: ${Spacing(20)};
  }

  @media (max-width: 430px) {
    padding: ${Spacing(5)};
    background: url('./assets/img/landing/background-form.jpeg') center center;
  }
`;

const Select = styled(SelectElem)`
  width: 236px;

  @media (max-width: 430px) {
    width: 98%;
  }
`;

const ButtonContainer = styled.div``;

const ButtonStyled = styled(ButtonElem)`
  font-size: ${Spacing(4)};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
  line-height: ${Spacing(4)};
  letter-spacing: -0.01em;
  text-align: center;
`;

const FormContainer = styled.div`
  display: grid;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  padding: ${Spacing(8)};
  width: 100%;
  max-width: 700px;
  border-radius: ${Spacing(5)};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  gap: ${Spacing(5)};
  align-self: center;
  margin-top: 165px;

  @media (max-width: 430px) {
    margin-top: 0px;
  }
`;

const TitleContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding-bottom: ${Spacing(8)};
`;

const TextStyled = styled(TextElem)`
  padding-bottom: ${Spacing(3)};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.BOLD]};

  @media (max-width: 730px) {
    font-size: ${Spacing(6)};
  }
`;

const TextStyledSubttile = styled(TextElem)`
  padding-bottom: ${Spacing(3)};

  @media (max-width: 730px) {
    font-size: ${Spacing(3.5)};
  }
`;

const GridContainer = styled(GridElem)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${Spacing(8)};
  column-gap: ${Spacing(4)};

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
