import React, { useState } from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { AlertActionElem } from '../../common/alert-action';
import { TabSecondaryElem } from '../../common/tab-secondary';
import { UserHeader } from './frame/user-header';
import { ConsultationReassignContainer } from '../consultation-reassign';
import { USER_ROLE } from '../../data/auth/constant';
import {
  CONSULTATION_DATA_INTER,
  CONSULTATION_STATUS,
} from '../../data/consultation/constant';
// import { PaymentLinkContainer } from '../payment-link';
import { tabListPsycho, tabListUser } from './constant';
import { ConsultationConfirmContainer } from '../consultation-confirm';
import { ClientContainer } from './frame/client';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { BackHeaderElem } from '../../common/back-header';
import { getIsAfterDate } from '../../lib/util/parseDate';
import { checkTimeDifference } from '../../lib/util/checkTimeDifference';
import { TabElem } from '../../common/tab';
import { TabThirdElem } from '../../common/tab-third';
import { PaymentLinkContainer } from '../payment-link';

export const Component: React.FC<{
  data: CONSULTATION_DATA_INTER;
  role?: USER_ROLE;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
}> = ({
  data,
  isSuccess,
  isLoading,
  isError,
  errorMessage,
  role = USER_ROLE.USER,
}) => {
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));
  const timezone = auth.user ? auth.user.timezone : null;
  const isCancel = data.status === CONSULTATION_STATUS.CANCEL;
  const isAfterConsultation = getIsAfterDate(data?.date, timezone);
  const isChangeTimeDisabled = checkTimeDifference(data?.date, timezone);

  const [active, setActive] = useState(1);
  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && (
        <>
          <BackHeaderElem id={data.id} />
          <Container>
            <UserHeader role={role} {...data} />
            <TabThirdElem
              tabList={role === USER_ROLE.USER ? tabListUser : tabListPsycho}
              active={active}
              setActive={setActive}
            />
            {active === 1 && (
              <Wrapper>
                <Double>
                  <PaymentLinkContainer
                    isChangeTimeDisabled={isChangeTimeDisabled}
                    role={role}
                    paymentId={data?.id}
                    price={data?.price}
                    duration={data?.duration}
                    isPaid={data?.payment?.isPaid}
                    isCancel={isCancel}
                    isAfterConsultation={isAfterConsultation}
                  />

                  <ConsultationReassignContainer
                    isChangeTimeDisabled={isChangeTimeDisabled}
                    isAfterConsultation={isAfterConsultation}
                    clientTimezone={data?.user?.timezone}
                    psychologistId={data?.psychologist?.id}
                    currentDate={{
                      timeView: data?.timeView,
                      dayView: data?.dayView,
                    }}
                    consultationId={data?.id}
                    role={role}
                    status={data?.status}
                  />
                </Double>
                {!isCancel && (
                  <ConsultationConfirmContainer
                    isPaid={data?.payment?.isPaid}
                    role={role}
                    timezone={timezone}
                    consultationId={data?.id}
                    url={data.eventLink ?? ''}
                    duration={data?.duration}
                    isCompleteClient={data?.isCompleteClient}
                    isCompletePsycho={data?.isCompletePsycho}
                  />
                )}
              </Wrapper>
            )}
            {active === 2 && (
              <ClientContainer
              user={data?.user}
              caretaker={data?.psychologist}
                category={data?.category}
                comment={data?.comment}
                role={role}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
  @media screen and (max-width: 1160px) {
    margin-bottom: 199px;
  }
`;

const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 1350px) {
    display: flex;
    flex-direction: column;
    gap: ${Spacing(2)};
  }
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(4)};
  margin-top: 38px;

  @media screen and (max-width: 1110px) {
    grid-gap: ${Spacing(3)};
  }

  @media screen and (max-width: 1160px) {
    margin-top: ${Spacing(6)};
    margin-bottom: ${Spacing(25)};
  }
`;
