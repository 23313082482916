import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { LayoutElem } from '../../common/layout';
import { HeaderContainer } from '../../epic/header';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import queryString from 'query-string';

import { AuthRecoveryPasswordContainer } from '../../epic/auth-recovery-password';
import { AuthHeader } from '../../common/auth-header';
import { GridElem } from '../../common/grid';
import { AuthPhotoLayout } from '../../common/auth_photo-layout';
import styled from 'styled-components';

export const Page: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const code = query.code;

  return (
    <AuthContainer>
      <LayoutAppElem oneColumn>
        <AuthPhotoLayout>
          <GridElem spacing={6}>
            <AuthHeader />
            <StyledLayoutElem size="small" direction="column" oneColumn>
              <AuthRecoveryPasswordContainer code={code} />
            </StyledLayoutElem>
          </GridElem>
        </AuthPhotoLayout>
      </LayoutAppElem>
    </AuthContainer>
  );
};

const AuthContainer = styled(PageElem)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 490px) {
    width: 100%;
    padding: 0 16px;
  }
`;

const StyledLayoutElem = styled(LayoutElem)`
  @media (max-width: 490px) {
    width: 100%;
  }
`;
