import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { action } from './action';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { convert } from './convert';
import { converPsychoFilter } from '../../data/user/convert';
import {
  ANIMAL_TYPE_ENUM,
  GENDER_ENUM,
  OPTION_INTER,
  SPECIALIST_ENUM,
  SPECIALIST_VIEW,
  USER_ITEM_DATA_RAW_INTER,
} from '../../data/user/constant';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';
import { ANIMAL_TYPE_VIEW } from './../../data/user/constant';
import { CARETAKER_LIST_MODULE_NAME } from '../psycho-list';
import { action as actionGetListCaretaker } from '../psycho-list/action';
import { filterOption } from '../psycho-list/constant';

const config = {
  [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: [],
};

const initial = {
  [FORM_VALUE_ENUM.CARETAKER_CATEGORY]: '',
  [FORM_VALUE_ENUM.DATE]: null,
  [FORM_VALUE_ENUM.TIME]: [],
  [FORM_VALUE_ENUM.ANIMAL_TYPE_LIST]: [],
  [FORM_VALUE_ENUM.SPECIALIST_LIST]: [],
  [FORM_VALUE_ENUM.PLACEMENT_LIST]: '',
  [FORM_VALUE_ENUM.GENDER]: null,
  [FORM_VALUE_ENUM.CITY]: '',
  [FORM_VALUE_ENUM.AGE]: null,
  [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]: '',
  [FORM_VALUE_ENUM.MEDICATION_EXPERIENCE]: '',
  [FORM_VALUE_ENUM.PRICE]: null,
};

interface initialType {
  [FORM_VALUE_ENUM.DATE]: null | string;
  [FORM_VALUE_ENUM.TIME]: OPTION_INTER[] | string[];
  [FORM_VALUE_ENUM.ANIMAL_TYPE_LIST]:
    | ANIMAL_TYPE_ENUM[]
    | []
    | ANIMAL_TYPE_ENUM;
  [FORM_VALUE_ENUM.SPECIALIST_LIST]: SPECIALIST_ENUM[] | [];
  [FORM_VALUE_ENUM.PLACEMENT_LIST]: string;
  [FORM_VALUE_ENUM.GENDER]: null | GENDER_ENUM;
  [FORM_VALUE_ENUM.CITY]: string;
  [FORM_VALUE_ENUM.AGE]: null | number;
  [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]: string;
  [FORM_VALUE_ENUM.MEDICATION_EXPERIENCE]: string;
  [FORM_VALUE_ENUM.PRICE]: null | number;
}

export const Container: React.FC = () => {
  const [initialValues, setInitialValues] = useState({});
  // const [active, setActive] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { animalType, date, city, time, service } = Object.fromEntries(
    queryParams.entries(),
  );

  const queryClient = useQueryClient();

  const handleRefetch = async (filterOption: filterOption) => {
    const data = await actionGetListCaretaker(filterOption);
    queryClient.setQueryData([CARETAKER_LIST_MODULE_NAME], data);
    // queryClient.invalidateQueries([CARETAKER_LIST_MODULE_NAME], { exact: true });

    // queryClient.refetchQueries([CARETAKER_LIST_MODULE_NAME], {
    //   queryFn: () => actionGetListCaretaker(filterOption),
    //   exact: true,
    // });
  };

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));
  const isLogged = () => auth.logged;

  // useEffect(() => {
  //   if (service == undefined || service == null || service == '') {
  //     return;
  //   }
  //   setActive(Number(service));
  // }, [service]);

  const preFetch = useQuery(MODULE_NAME, action, { retry: 1 });

  useEffect(() => {
    if (preFetch.isFetched) {
      const convertData = converPsychoFilter(preFetch.data);

      const updatedValues = {
        [FORM_VALUE_ENUM.CARETAKER_CATEGORY]: service || null,
        [FORM_VALUE_ENUM.DATE]: date || null,
        [FORM_VALUE_ENUM.TIME]: time ? [{ label: time, value: time }] : [],
        [FORM_VALUE_ENUM.ANIMAL_TYPE_LIST]: animalType
          ? [{ label: ANIMAL_TYPE_VIEW[animalType], value: animalType }]
          : convertData.workAnimalType?.length > 0
          ? convertData.workAnimalType
          : [],
        [FORM_VALUE_ENUM.SPECIALIST_LIST]: convertData.specialistWith || [],
        [FORM_VALUE_ENUM.PLACEMENT_LIST]: convertData.placement || '',
        [FORM_VALUE_ENUM.GENDER]: convertData.gender || null,
        [FORM_VALUE_ENUM.CITY]: city || convertData.city || '',
        [FORM_VALUE_ENUM.AGE]: convertData.age || null,
        [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]:
          convertData.ableCompatibilityAnimal || '',
        [FORM_VALUE_ENUM.MEDICATION_EXPERIENCE]:
          convertData.medicationExperience || '',
        [FORM_VALUE_ENUM.PRICE]: null,
      };

      setInitialValues(updatedValues); 
      handleRefetch(convert(updatedValues));

    }
  }, [preFetch.isFetched]);

  const validate = (values: FormikValues) => validation(values, config);

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: () => {
      saveFormikValuesToLocalStorage({
        ...initial,
        ...formik.values,
      });
      setInitialValues({ ...formik.values });
      handleRefetch(convert(formik.values));
    },
  });

  const onResetForm = () => {
    formik.resetForm({ values: initial });
    saveFormikValuesToLocalStorage(initial);
  };


  const isLoading = () => {
    if (!isLogged()) {
      return false;
    }

    if (preFetch.isLoading) {
      return true;
    }
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  const saveFormikValuesToLocalStorage = (values: FormikValues) => {
    const keys = Object.keys(initial);
    keys.forEach((key) => {
      const value = values[key];

      if (
        Array.isArray(value) &&
        value.every(
          (item) =>
            item.hasOwnProperty('label') && item.hasOwnProperty('value'),
        )
      ) {
        const valuesOnly = value.map((item) => item.value);
        localStorage.setItem(key, JSON.stringify(valuesOnly));
        return;
      }
      if (value instanceof Date) {
        localStorage.setItem(key, JSON.stringify(value.toISOString()));
        return;
      }
      if (typeof value === 'number' || value === null) {
        localStorage.setItem(key, JSON.stringify(value));
        return;
      }
      if (typeof value === 'string') {
        localStorage.setItem(key, JSON.stringify(value));
        return;
      }
    });
  };

  const getFormikValuesFromLocalStorage = (): FormikValues => {
    const keys = Object.keys(initial);

    const formikValues: FormikValues = {};

    keys.forEach((key) => {
      const storedValue = localStorage.getItem(key);

      if (storedValue === null) {
        formikValues[key] = null;
        return;
      }
      const parsedValue = JSON.parse(storedValue);

      if (Array.isArray(parsedValue)) {
        if (key == FORM_VALUE_ENUM.ANIMAL_TYPE_LIST) {
          formikValues[key] = parsedValue.map((value) => ({
            label: ANIMAL_TYPE_VIEW[value],
            value: value,
          }));
          return;
        }
        if (key == FORM_VALUE_ENUM.SPECIALIST_LIST) {
          formikValues[key] = parsedValue.map((value) => ({
            label: SPECIALIST_VIEW[value],
            value: value,
          }));
          return;
        }
        if (parsedValue.every((item) => typeof item === 'string')) {
          formikValues[key] = parsedValue.map((value) => ({
            label: value,
            value: value,
          }));
          return;
        }
        formikValues[key] = parsedValue;
        return;
      }
      formikValues[key] = parsedValue;
    });

    return formikValues;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const getFieldValue = (name: FORM_VALUE_TYPE) => {
    return formik.values[name];
  };

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    return formik.setFieldValue(name, e?.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item?.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      // active={active}
      // setActive={setActive}
      formik={formik}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoading={isLoading()}
      onChangeSelect={onChangeSelect}
      getFieldError={getFieldError}
      getFieldValue={getFieldValue}
      isFieldError={isFieldError}
      onResetForm={onResetForm}
      setInitialValueForm={setInitialValues}
      getFormikValuesFromLocalStorage={getFormikValuesFromLocalStorage}
    />
  );
};
