import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import parse from 'html-react-parser';
import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { scrollToSection } from '../../lib/util/scrollToSection';
import { LANDING_ID_ENUM } from '../home-header/constant';
import { AuthPhoto } from '../../common/auth-photo';
import { PropsComponent } from './constant';

export const Component: React.FC<PropsComponent> = ({ svgSrc }) => {
  return (
    <HeroContainer>
      <HeroBlock>
        <HeroText>
          <SmallImage src={svgSrc} alt="fixed photo" />

          <HeroTitle size="mainHeadTitle" fontFamily="Raleway">
            {i18n.t('LANDING.HOME_HERO.TITLE')}
          </HeroTitle>
          <HeroTitle
            size="mainHeadTitle"
            fontFamily="Raleway"
            children={parse(
              i18n.t('LANDING.HOME_HERO.SPECIAL_WORDS', new Object()),
            )}
          />

          <HeroSubtitle size="main" fontFamily="Raleway">
            {i18n.t('LANDING.HOME_HERO.SUBTITLE')}
          </HeroSubtitle>
        </HeroText>

        <HeroDecs
          size="label"
          fontFamily="Raleway"
          children={parse(i18n.t('LANDING.HOME_HERO.DESCRIPTION'))}
        />

        <ButtonStyle
          onClick={() => scrollToSection(LANDING_ID_ENUM.SERVICES)}
          type="landing"
        >
          {i18n.t('LANDING.HOME_HERO.BUTTON')}
        </ButtonStyle>
      </HeroBlock>
      <HeroImg />
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  position: relative;
  font-family: 'Raleway' !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(25)} 0px 0px ${Spacing(40)};
  margin: 0 auto;
  height: 97vh;
  max-height: ${Spacing(300)};
  width: 100%;
  max-width: ${Spacing(350)};
  display: flex;
  gap: ${Spacing(17.75)};
  align-items: center;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  @media (max-width: 1200px) {
    padding: ${Spacing(20)} ${Spacing(15)} 0px ${Spacing(15)};
  }

  @media (max-width: 992px) {
    padding: ${Spacing(15)} ${Spacing(10)} 0px ${Spacing(10)};
    height: 100vh;
  }

  @media (max-width: 768px) {
    padding: ${Spacing(10)} ${Spacing(5)} 0px ${Spacing(5)};
  }

  @media (max-width: 430px) {
    height: 100vh;
  }
`;

const HeroBlock = styled.div`
  max-width: ${Spacing(125)};
  max-height: ${Spacing(100)};
  display: grid;
  gap: ${Spacing(8)};

  @media (max-width: 992px) {
    max-width: 100%;
    max-height: 120px;
  }
`;

const HeroText = styled.div`
  display: grid;
  min-width: ${Spacing(120)};
  gap: ${Spacing(4)};

  @media (max-width: 730px) {
    min-width: ${Spacing(75)};
    gap: 0;
    margin-left: 36px;
  }

  @media (max-width: 430px) {
    margin-left: 0;
  }
`;

const HeroTitle = styled(TextElem)`
  white-space: nowrap;
  font-family: 'Raleway' !important;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  line-height: 150%;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.BOLD]};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]};

  @media (max-width: 730px) {
    font-size: 40px;
    white-space: pre-line;
  }
`;

const HeroSubtitle = styled(TextElem)`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  line-height: 160%;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  font-family: 'Raleway' !important;

  @media (max-width: 730px) {
    font-size: ${Spacing(4.5)};
  }
`;

const HeroDecs = styled(TextElem)`
  white-space: nowrap;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]};
  line-height: 170%;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  font-family: 'Raleway' !important;

  @media (max-width: 730px) {
    font-size: ${Spacing(4)};
    line-height: 160%;
    margin-left: 36px;
  }

  @media (max-width: 460px) {
    font-size: ${Spacing(4)};
    margin-left: 0;
    white-space: pre-line;
  }
`;

const HeroImg = styled(AuthPhoto)`
  max-width: 80%;
`;

const SmallImage = styled.img`
  position: absolute;
  top: 500px;
  right: 130px;
  transform: translate(-90%, -50%);
  border-radius: 24px;
  width: 300px;
  height: 293px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;

  @media (max-width: 730px) {
    position: absolute;
    width: 235px;
    height: auto;
    top: -30px;
    right: -50px;
    transform: translate(-507px, 12px);
  }

  @media (max-width: 430px) {
    position: absolute;
    width: 274px;
    height: 316px;
    top: 70px;
    transform: translateX(-207px);
  }
`;

const ButtonStyle = styled(ButtonElem)`
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
  line-height: 18px;
  letter-spacing: -0.01em;
  padding: ${Spacing(5)} 44px;

  @media (max-width: 730px) {
    margin-left: 36px;
  }

  @media (max-width: 460px) {
    margin-left: 0;
    width: 100%;
  }
`;
