import React, { useState } from 'react';
import styled from 'styled-components';
import { PAYMENT_ITEM_DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';

import { CardElem } from '../../../common/card';

import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import { PaymentItemContainer } from '../../../epic/payment-item';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../../theme/value';

const Elem: React.FC<PAYMENT_ITEM_DATA_INTER> = ({
  id,
  createDate,
  amount,
  valute,
  user,
  psychologist,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const modalClose = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    setModalVisible(false);
  };
  const modalOpen = () => {
    setModalVisible(true);
  };
  return (
    <>
      {modalVisible && (
        <PaymentItemContainer
          paymentId={id}
          visible={modalVisible}
          onClose={modalClose}
        />
      )}
      <CardDesktop handleClick={modalOpen}>
        <TextElem size="semiSmall" oneLine color="textPrimary" tid={id} />
        <TextElem
          size="semiSmall"
          tid={createDate}
          oneLine
          color="textPrimary"
        />
        <TextElem
          type="medium"
          size="semiSmall"
          oneLine
          tvalue={{ valute, amount }}
          tid="PAYMENT.ITEM.PRICE"
        />
        <TextElem
          size="semiSmall"
          tid={user.name}
          oneLine
          color="textPrimary"
        />
        <TextElem
          size="semiSmall"
          tid={psychologist.name}
          oneLine
          color="textPrimary"
        />
      </CardDesktop>
      <CardMobile handleClick={modalOpen}>
        <Row>
          <Column>
            <TextElem
              tid="PAYMENT.ITEM.PRICE_TITLE"
              size="verySmall"
              color="textSecondary"
              oneLine
            />
            <TextElem
              size="input"
              tid="PAYMENT.ITEM.PRICE"
              tvalue={{ valute, amount }}
              oneLine
              color="default"
            />
          </Column>

          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.ID"
            />
            <TextElem size="input" tid={id} oneLine color="textPrimary" />
          </Column>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.CREATE_DATE"
            />
            <TextElem
              size="input"
              color="textPrimary"
              tid={createDate}
              oneLine
            />
          </Column>

          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.USER"
            />
            <TextElem
              size="semiSmall"
              tid={user.name}
              oneLine
              color="textPrimary"
            />
          </Column>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.PSYCHOLOGIST"
            />
            <TextElem
              size="semiSmall"
              tid={psychologist.name}
              oneLine
              color="textPrimary"
            />
          </Column>
        </Row>
      </CardMobile>
    </>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
  width: 100%;
  grid-gap: ${Spacing(7)};

  @media screen and (max-width: 375px) {
    grid-template-columns: 1fr 75px 1fr;
  }
`;

const CardMobile = styled(CardElem)<{}>`
  display: none;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  gap: ${Spacing(12)};
  padding: ${Spacing(4)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD_LIST
  ]}px;

  @media screen and (max-width: 1160px) {
    display: flex;
    flex-direction: column;
  }
`;

const CardDesktop = styled(CardElem)<{}>`
  display: grid;
  grid-column: span 5;
  grid-template-columns: subgrid;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  gap: ${Spacing(12)};
  padding: ${Spacing(4)} ${Spacing(5)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: 14px;
  height: 56px;
  cursor: pointer;
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

export { Elem as PaymentItemElem };
