import React from 'react';
import { RouteComponentProps } from 'react-router';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { useSelector } from 'react-redux';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';
import { ConsultationUserContainer } from '../../epic/consultation-user-item';
import { ConsultationAdminContainer } from '../../epic/consultation-admin-item';
import { MobileMenuContainer } from '../../epic/mobile-menu';

export const Page: React.FC<RouteComponentProps> = () => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);

  const isAdmin = state.user?.role === USER_ROLE.ADMIN;

  return (
    <PageElem>
      <LayoutAppElem>
        <LayoutElem size="default">
          <LayoutSectionElem spacing={5}>
            {!isAdmin && <ConsultationUserContainer role={state.user?.role} />}
            {isAdmin && <ConsultationAdminContainer />}
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
      <DesktopMenuAppContainer />
      <LayoutFooterElem>
        <MobileMenuContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};
