import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';

export const Elem: React.FC<{}> = () => {
  return (
    <AuthHeader>
      <LogoContainer>
        <LogoSvg src="./assets/svg/header/pets.svg" alt="pets Logo" />
      </LogoContainer>
    </AuthHeader>
  );
};

const LogoSvg = styled.img`
  width: 129px;
  height: 46px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spacing(2.25)};
`;

const AuthHeader = styled.div`
  width: 100%;
  display: flex;
  gap: ${Spacing(4)};
  justify-content: center;
  align-items: center;
`;
