import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import {
  ANIMAL_TYPE_OPTION_LIST,
  COMPATIBILITY_ANIMAL_OPTION_LIST,
  FORM_VALUE_ENUM,
  PLACEMENT_OPTION_LIST,
  SPECIALIST_OPTION_LIST,
  TRANSPORTATION_OPTION_LIST,
} from './constant';

import { TextElem } from '../../common/text';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { SelectElem } from '../../common/select';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { SkeletonFormElem } from '../../common/skeleton-form';
import { SPECIALIST_ENUM } from '../../data/user/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue?: Function;
  setValue?: Function;
  onChangeSelect: (name: string, values: any) => void;
  isLoadingAction: boolean;
  isSuccessAction: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setValue = () => {},
  setFieldValue,
  isLoadingAction,
  onChangeSelect,
  isSuccessAction,
}) => {
  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected?.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };

  return (
    <>
      <FormElem gap={7} onSubmit={formik.handleSubmit}>
        {isLoadingAction && <LoaderElem />}
        {isLoading && <SkeletonFormElem />}

        {isSuccess && (
          <Container>
            {/* <FieldTextAreaElem
              isDot
              name={FORM_VALUE_ENUM.ABOUT_MY_PATH}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.ABOUT_MY_PATH)}
              title="USER.PSYCHOLOGIST.ABOUT_MY_PATH"
              placeholder="USER.PSYCHOLOGIST.ABOUT_MY_PATH_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.ABOUT_MY_PATH)}
              error={isFieldError(FORM_VALUE_ENUM.ABOUT_MY_PATH)}
            /> */}

            {/* <FieldTextAreaElem
              isDot
              name={FORM_VALUE_ENUM.ABOUT_MY_SERVICE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.ABOUT_MY_SERVICE)}
              title="USER.PSYCHOLOGIST.ABOUT_MY_SERVICE"
              placeholder="USER.PSYCHOLOGIST.ABOUT_MY_SERVICE_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.ABOUT_MY_SERVICE)}
              error={isFieldError(FORM_VALUE_ENUM.ABOUT_MY_SERVICE)}
            /> */}

            <SelectElem
              placeholder="USER.PSYCHOLOGIST.ANIMAL_TYPE_PLACEHOLDER"
              isDot
              isMulti
              name={FORM_VALUE_ENUM.ANIMAL_TYPE_LIST}
              options={ANIMAL_TYPE_OPTION_LIST}
              title="USER.PSYCHOLOGIST.ANIMAL_TYPE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.ANIMAL_TYPE_LIST)}
              error={isFieldError(FORM_VALUE_ENUM.ANIMAL_TYPE_LIST)}
              value={getFieldValue(FORM_VALUE_ENUM.ANIMAL_TYPE_LIST)}
              onChange={onChangeSelect}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              customComponents={{ MultiValueContainer: multiValueContainer }}
            />

            {/* <FieldTextAreaElem
              name={FORM_VALUE_ENUM.REQUEST_CUSTOM}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.REQUEST_CUSTOM)}
              placeholder="USER.PSYCHOLOGIST.REQUEST_CUSTOM_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.REQUEST_CUSTOM)}
              error={isFieldError(FORM_VALUE_ENUM.REQUEST_CUSTOM)}
            /> */}

            <SelectElem
              placeholder="USER.PSYCHOLOGIST.SPECIALIST_PLACEHOLDER"
              isDot
              isMulti
              name={FORM_VALUE_ENUM.SPECIALIST_LIST}
              options={SPECIALIST_OPTION_LIST}
              title="USER.PSYCHOLOGIST.SPECIALIST"
              errorMessage={getFieldError(FORM_VALUE_ENUM.SPECIALIST_LIST)}
              error={isFieldError(FORM_VALUE_ENUM.SPECIALIST_LIST)}
              value={getFieldValue(FORM_VALUE_ENUM.SPECIALIST_LIST)}
              onChange={onChangeSelect}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              customComponents={{ MultiValueContainer: multiValueContainer }}
            />
            <SelectElem
              placeholder="USER.PSYCHOLOGIST.TRANSPORTATION_PLACEHOLDER"
              isDot
              isMulti
              name={FORM_VALUE_ENUM.TRANSPORTATION_LIST}
              options={TRANSPORTATION_OPTION_LIST}
              title="USER.PSYCHOLOGIST.TRANSPORTATION"
              errorMessage={getFieldError(FORM_VALUE_ENUM.TRANSPORTATION_LIST)}
              error={isFieldError(FORM_VALUE_ENUM.TRANSPORTATION_LIST)}
              value={getFieldValue(FORM_VALUE_ENUM.TRANSPORTATION_LIST)}
              onChange={onChangeSelect}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              customComponents={{ MultiValueContainer: multiValueContainer }}
            />
            <SelectElem
              placeholder="USER.PSYCHOLOGIST.PLACEMENT_PLACEHOLDER"
              isDot
              isMulti
              name={FORM_VALUE_ENUM.PLACEMENT_LIST}
              options={PLACEMENT_OPTION_LIST}
              title="USER.PSYCHOLOGIST.PLACEMENT"
              errorMessage={getFieldError(FORM_VALUE_ENUM.PLACEMENT_LIST)}
              error={isFieldError(FORM_VALUE_ENUM.PLACEMENT_LIST)}
              value={getFieldValue(FORM_VALUE_ENUM.PLACEMENT_LIST)}
              onChange={onChangeSelect}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              customComponents={{ MultiValueContainer: multiValueContainer }}
            />
            {/* <FieldTextAreaElem
              name={FORM_VALUE_ENUM.METHOD_CUSTOM}
              onChane={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.METHOD_CUSTOM)}
              placeholder="USER.PSYCHOLOGIST.METHOD_CUSTOM"
              errorMessage={getFieldError(FORM_VALUE_ENUM.METHOD_CUSTOM)}
              error={isFieldError(FORM_VALUE_ENUM.METHOD_CUSTOM)}
            /> */}

            <SelectElem
              placeholder="USER.PSYCHOLOGIST.COMPATIBILITY_ANIMAL_PLACEHOLDER"
              name={FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL}
              onChange={setFieldValue}
              options={COMPATIBILITY_ANIMAL_OPTION_LIST}
              title="USER.PSYCHOLOGIST.COMPATIBILITY_ANIMAL"
              errorMessage={getFieldError(FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL)}
              error={isFieldError(FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL)}
              value={setValue(
                COMPATIBILITY_ANIMAL_OPTION_LIST,
                FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL,
              )}
            />

            <TitleContainer>
              <Dot />
              <TextElem
                tid="USER.PSYCHOLOGIST.REQUIRED"
                type="light"
                size="input"
                color="textSecondary"
              />
            </TitleContainer>

            <ButtonElem
              disabled={isSubmitDisabled()}
              type="submit"
              tid="CATEGORY.DATA.BUTTON"
            />
            {isError && <AlertActionElem text={errorMessage} />}
            {isSuccessAction && (
              <AlertActionElem type="success" tid="CATEGORY.DATA.SUCCESS" />
            )}
          </Container>
        )}
      </FormElem>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;
