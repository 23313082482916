export function getYearLabel(years: number): string {
  // Получаем последние две цифры для правильной обработки 11-19
  const lastTwoDigits = years % 100;

  // Если последние две цифры между 11 и 19, всегда используем "лет"
  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return `${years} років`;
  }

  // Получаем последнюю цифру числа
  const lastDigit = years % 10;

  // Выбираем правильную форму слова
  if (lastDigit === 1) {
    return `${years} рік`;
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return `${years} роки`;
  } else {
    return `${years} років`;
  }
}
