import React, { ReactNode } from 'react';
import { IonSkeletonText } from '@ionic/react';
import { Spacing } from '../../theme';

import styled from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { GridElem } from '../grid';
import { VALUE_OPACITY_ENUM } from '../../theme/value';
import { COLOR_ENUM } from '../../theme/color';

export const Elem: React.FC = () => {
  return (
    <Wrapper>
      <Card spacing={3}>
        <PhotoContainer>
          <SkeletonPhoto />
          <Container spacing={2}>
            <SkeletonFieldPercent width={50} animated />
            <SkeletonFieldPercent width={30} animated />
          </Container>
        </PhotoContainer>
      </Card>

      <Card spacing={3}>
        <Container spacing={2}>
          <SkeletonFieldPercent width={95} animated />
          <SkeletonFieldPercent width={80} animated />
          <SkeletonFieldPercent width={88} animated />
        </Container>
      </Card>

      <Card spacing={3}>
        <Container spacing={2}>
          <SkeletonFieldPercent width={95} animated />
          <SkeletonFieldPercent width={80} animated />
          <SkeletonFieldPercent width={88} animated />
        </Container>
      </Card>
      <Card spacing={3}>
        <Container spacing={2}>
          <SkeletonFieldPercent width={95} animated />
          <SkeletonFieldPercent width={80} animated />
          <SkeletonFieldPercent width={88} animated />
        </Container>
      </Card>
    </Wrapper>
  );
};

const PhotoContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: ${Spacing(4)};
`;

const SkeletonPhoto = styled(IonSkeletonText)`
  height: 60px;
  width: 60px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
  margin: 0;
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${Spacing(3)};
`;

const Card = styled(GridElem)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(4)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.SKELETON]}px;
  width: 100%;
`;

const SkeletonFieldPercent = styled(IonSkeletonText)<{ width: number }>`
  height: 12px;
  width: ${({ width }) => width}%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
  margin: 0;
`;

const Container = styled(GridElem)`
  align-content: flex-start;
  gap: ${Spacing(3)};
`;
