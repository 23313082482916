import React from 'react';
import { RouteComponentProps } from 'react-router';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { HeaderContainer } from '../../epic/header';
import calendarIcon from '../../asset/svg/menu-app/calendar.svg';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { ScheduleListContainer } from '../../epic/schedule-list';
import { ScheduleItemCreateContainer } from '../../epic/schedule-item-create';
import { MobileMenuContainer } from '../../epic/mobile-menu';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <HeaderContainer icon={calendarIcon} title="SCHEDULE.PAGE.HEADER" />

        <LayoutElem>
          <LayoutSectionElem spacing={5}>
            <LayoutPage>
              <ScheduleItemCreateContainer />
              <ScheduleListContainer />
            </LayoutPage>
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
      <DesktopMenuAppContainer />
      <LayoutFooterElem>
        <MobileMenuContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};

const LayoutPage = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: ${Spacing(5)};
`;
