import { paymentView } from './../../data/payment/constant';
import { CONSULTATION_RAW_INTER } from '../../data/consultation/constant';
import {
  GENDER_ENUM,
  methodViewObj,
  requestViewObj,
} from '../../data/user/constant';
import { TIMEZONE_VIEW, TIMEZONE_ENUM } from '../../data/user/timezone';
import { convertDateToLocal } from '../../lib/util/dateConvert';
import { getYearLabel } from '../../lib/util/yearConvert';
import { CATEGORY_VIEW } from '../profile-psycho-create-category/constant';
import { GENDER_VIEW } from '../profile-psycho/constant';

export const convert = (
  values: CONSULTATION_RAW_INTER,
  timezone: number,
): any => {
  const { time, day } = convertDateToLocal(values.date, timezone);
  const { time: paymentTime, day: paymentDay } = convertDateToLocal(
    values?.payment?.createDate,
    timezone,
  );

  const data = {
    ...values,
    timeView: time,
    dayView: day,
    paymentTimeView: paymentTime,
    paymentDayView: paymentDay,
    paymentView: paymentView[values.payment?.system],
    category: CATEGORY_VIEW[values?.category],
    user: {
      ...values.user,
      methodList: values?.user.methodList?.map((e) => methodViewObj[e]),
      requestList: values?.user.requestList?.map((e) => requestViewObj[e]),
      needGender: GENDER_VIEW[values.user.needGender as GENDER_ENUM],
      timezone: TIMEZONE_VIEW[values.user.timezone as TIMEZONE_ENUM],
      numberTimezone: values.user.timezone,
      needAge: getYearLabel(values.user.needAge || 0),
    },
    psychologist: {
      ...values.psychologist,
      gender: GENDER_VIEW[values.psychologist.gender as GENDER_ENUM],
      timezone: TIMEZONE_VIEW[values.psychologist.timezone as TIMEZONE_ENUM],
      age: getYearLabel(values.psychologist.age || 0),
    },
  };

  return data;
};
