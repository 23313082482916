import React from 'react';
import { useHistory } from 'react-router';
import { Component } from './component';
import { CONSULTATION_PAGE_PATH } from '../../page/consultation';

export const Container: React.FC = () => {
  const history = useHistory();

  const onSubmitForm = () => {
    history.push(CONSULTATION_PAGE_PATH);
  };

  return <Component onSubmitForm={onSubmitForm} />;
};
