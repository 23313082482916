import React from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router';

import { PSYCHO_ITEM_DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../../theme/value';
import { FlexElem } from '../../../common/flex';

export const Card: React.FC<{
  data: PSYCHO_ITEM_DATA_INTER;
}> = ({ data }) => {
  const href = `/psycho-profile/${data.id}`;

  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };

  const aboutMeStr =
    data?.profileDescription && data?.profileDescription.length > 84
      ? `${data.profileDescription.substring(0, 83)}...`
      : data?.profileDescription;

  return (
    <AStyled href={href} onClick={onClick}>
      <Container>
        <ImgStyled src={data?.photo?.url} />
        {/* <InfoContainer> */}
        <DescriptionContainer>
          <TextElem
            tid={data.name}
            style={{ fontWeight: 700 }}
            size="main"
            color="textPrimary"
          />
          <FlexElem spacing={1.5}>
            {(data?.city || data?.country) && (
              <TextElem
                tid="CARETAKER_LIST.LIST.CITY_COUNTRY"
                tvalue={{ city: data?.city, country: data?.country }}
                type="semi-bold"
                size="input"
                color="textButtonClick"
              />
            )}
            {data?.yearExperience && (
              <>
                <Dot />
                <TextElem
                  // tid="CARETAKER_LIST.LIST.EXPERIENCE"
                  tid={
                    data?.yearExperience < 5
                      ? data?.yearExperience == 1
                        ? `${data?.yearExperience} рік досвіду`
                        : `${data?.yearExperience} роки досвіду`
                      : `${data?.yearExperience} років досвіду`
                  }
                  type="semi-bold"
                  size="input"
                  color="iconThird"
                />
              </>
            )}
            {data?.nationality && (
              <>
                <Dot />
                <TextElem
                  tid={data?.nationality}
                  type="semi-bold"
                  size="input"
                  color="iconThird"
                />
              </>
            )}
          </FlexElem>
          <AboutMeWrapper>
            <TextElem
              tid={aboutMeStr || ''}
              type="medium"
              color="iconThird"
              size="input"
            />
          </AboutMeWrapper>
          <PriceContainer>
            {data?.price ? (
              <TextElem
                style={{ fontWeight: 700 }}
                tid="CARETAKER_LIST.LIST.PRICE"
                tvalue={{ value: data?.price }}
                color="textPrimary"
              />
            ) : (
              data?.minPrice && (
                <>
                  <TextElem
                    tid="Від"
                    type="semi-bold"
                    color="iconThird"
                    size="input"
                  />
                  <TextElem
                    style={{ fontWeight: 700 }}
                    tid="CARETAKER_LIST.LIST.PRICE"
                    tvalue={{ value: data?.minPrice }}
                    color="textPrimary"
                  />
                  <TextElem
                    tid="за послугу"
                    type="semi-bold"
                    color="iconThird"
                    size="input"
                  />
                </>
              )
            )}
          </PriceContainer>
        </DescriptionContainer>
        {/* </InfoContainer> */}
      </Container>
    </AStyled>
  );
};

const Dot = styled.div`
  height: 4px;
  width: 4px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.ICON_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const AboutMeWrapper = styled.div`
  max-height: 50px;
  overflow: hidden;
  hyphens: auto;
  text-overflow: ellipsis;
`;

const AStyled = styled.a`
  text-decoration: none;
  width: 100%;
  max-width: 530px;
`;

const ImgStyled = styled.img`
  height: ${Spacing(35)};
  width: 100%;
  max-width: ${Spacing(35)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  object-fit: cover;
`;

const PriceContainer = styled.div`
  display: flex;
  gap: ${Spacing(1.5)};
  align-items: center;
  justify-self: end;
  margin-top: ${Spacing(1)};
`;

const InfoContainer = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${Spacing(5)};
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing(3)};
`;

const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  padding: ${Spacing(5)};
  width: 100%;
  max-width: 530px;
  align-items: start;
  justify-content: center;
  gap: ${Spacing(4)};
  cursor: pointer;
  transition: ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;
