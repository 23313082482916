import styled from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { UseMutationResult } from 'react-query';

import { Center, Content, Input } from './file';
import { ModalElem } from '../../../common/modal';
import { ReactComponent as DraggingFileSvg } from '../../../asset/svg/common/arrow-down.svg';
import { SettingContainer, IconSettingBackground, IconImg } from './image';

import refreshFileSvg from '../../../asset/svg/common/refresh.svg';
import trashFileSvg from '../../../asset/svg/common/trash.svg';
import playSvg from '../../../asset/svg/common/play.svg';
import { ReactComponent as VideoIcon } from '../../../asset/svg/file/video-square.svg';
import { AlertActionElem } from '../../../common/alert-action';

export const VideoComponent: React.FC<{
  data?: any;
  handleReselect: (e: any) => void;
  handleReset: (e: any) => void;
  openModalPreview: (e: any) => void;
  closeModalPreview: (e: any) => void;
  uploadFile: UseMutationResult;
  defaultValue?: string;
  getInputProps: any;
  getRootProps: any;
  error?: string;
  modalPreviewVisible: boolean;
  isDragActive: boolean;
}> = ({
  data,
  getInputProps,
  getRootProps,
  uploadFile,
  defaultValue,
  error,
  handleReselect,
  handleReset,
  modalPreviewVisible,
  openModalPreview,
  closeModalPreview,
  isDragActive,
}) => {
  const stopPropagation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };
  return (
    <>
      {!uploadFile.isLoading && !uploadFile.isSuccess && !defaultValue && (
        <div {...getRootProps()}>
          <Input {...getInputProps()} />

          <Content spacing={3}>
            <Center spacing={2}>
              {isDragActive ? <DraggingFileSvg /> : <VideoIcon />}
              <TextElem
                tid={
                  isDragActive
                    ? 'COMMON.FILE.DRAGGING'
                    : 'COMMON.FILE.VIDEO_CONTENT_TITLE'
                }
                size="semiSmall"
                color={error || false ? 'textSecondary' : 'default'}
                type="regular"
              />
              {!isDragActive && (
                <TextElem
                  color={error ? 'error' : 'textSecondary'}
                  type="light"
                  size="small"
                  tid={!!error ? error : 'COMMON.FILE.VIDEO_SIZE_TITLE'}
                />
              )}
            </Center>
          </Content>
        </div>
      )}

      {(!!uploadFile.isSuccess ||
        (!!defaultValue && !uploadFile.isLoading)) && (
        <Background onClick={openModalPreview}>
          <VideoPreviewContainer>
            <DotPlay>
              <PlayImg src={playSvg} />
            </DotPlay>
            <TextElem
              tid="COMMON.FILE.VIEW_VIDEO"
              size="semiSmall"
              type="regular"
              color="default"
            />
          </VideoPreviewContainer>
          {!!error && <AlertActionElem tid={error} />}

          <SettingContainer onClick={stopPropagation} className="setting">
            <IconSettingBackground onClick={handleReselect}>
              <IconImg src={refreshFileSvg} />
            </IconSettingBackground>
            <IconSettingBackground onClick={handleReset}>
              <IconImg src={trashFileSvg} />
            </IconSettingBackground>
          </SettingContainer>

          <ModalElem
            open={modalPreviewVisible}
            onClose={(e: any) => closeModalPreview(e)}
          >
            <VideoStyled controls style={{ width: '100%', height: 'auto' }}>
              <source src={data?.url || defaultValue} type="video/mp4" />
              Your browser does not support the video tag.
            </VideoStyled>
          </ModalElem>
        </Background>
      )}
    </>
  );
};

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DROPDOWN
  ]}px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const DotPlay = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.12);
  justify-content: center;
`;

const PlayImg = styled.img`
  height: 18px;
  width: auto;
`;

const VideoPreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

export const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 117px;
`;

const Background = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing(2)};
  position: relative;
  .icon {
    display: none;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    position: absolute;
  }
  :not(:has(.setting:hover)):hover {
    .icon {
      display: flex;
    }
  }
`;

export const ZoomIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  align-items: center;
  justify-content: center;
  background: white;
`;
