import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';

import { HeaderContainer } from '../../epic/header';
import { LayoutFooterElem } from '../../common/layout-footer';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { PaymentListContainer } from '../../epic/payment-list';
import paymentIcon from '../../asset/svg/menu-app/payment.svg';
import { MobileMenuContainer } from '../../epic/mobile-menu';

export const Page: React.FC<{}> = ({}) => {
  return (
    <PageElem>
      <LayoutAppElem>
        <HeaderContainer
          icon={paymentIcon}
          title="PAYMENT.HEADER"
          size="default"
        />
        <LayoutElem size="default">
          <LayoutSectionElem spacing={8}>
            <PaymentListContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
        <DesktopMenuAppContainer />
      <LayoutFooterElem>
        <MobileMenuContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};
