import React, { useEffect, useState } from 'react';
import { Component } from './component';
import { i18n } from '../../lib/lang';

export const Container: React.FC = () => {
  const [svgSrc, setSvgSrc] = useState('./assets/svg/grafic.svg');
  const [longText, setLongText] = useState<string>(
    i18n.t('LANDING.HOME_ABOUT.STEPS.ONE') as string,
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 730) {
        setSvgSrc('./assets/svg/mobile-grafic.svg');
        setLongText(i18n.t('LANDING.HOME_ABOUT.STEPS.ONE'));
      } else {
        setSvgSrc('./assets/svg/grafic.svg');
        setLongText('');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <Component svgSrc={svgSrc} longText={longText} />;
};
