import { OPTION_INTER } from '../../data/user/constant';
import { FORM_VALUE_INTER } from './constant';

const toBoolean = (value: string | null | undefined): boolean | undefined => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return undefined;
};

export const convert = (values: any) => {
  return {
    ...values,
    gender: typeof values?.gender != 'object' ? values.gender : null,
    timezone: Number(values?.timezone),
    language: values?.language.map((e: OPTION_INTER) => e.value),
    animalSize:
      typeof values?.animalSize === 'string' ? values.animalSize : null,
    animalType:
      typeof values?.animalType === 'string' ? values.animalType : null,
    needGender:
      typeof values?.needGender === 'number' ? values.needGender : null,
    needPreviousExperience: toBoolean(values?.needPreviousExperience),
    needPlacement:
      typeof values?.needPlacement === 'string' ? values.needPlacement : null,
    needLanguage: values?.needLanguage.map((e: OPTION_INTER) => e.value),
    specialistNeeded: values?.specialistNeeded.map(
      (e: OPTION_INTER) => e.value,
    ),
    needCompatibility: toBoolean(values?.needCompatibility),
    compatibilityAnimal: toBoolean(values?.compatibilityAnimal),
    vaccination: values?.vaccination.map((e: OPTION_INTER) => e.value),
    age: values?.age === '' ? null : values.age,

    // requestList: values.requestList.map((e: OPTION_INTER) => e.value),
    // methodList: values.methodList.map((e: OPTION_INTER) => e.value),
    // lgbtFrendly: values.lgbtFrendly === 'true' ? true : false,
  };
};
