import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { LayoutElem } from '../../common/layout';

import { GridElem } from '../../common/grid';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { AuthVerificationContainer } from '../../epic/auth-verification';
import { AUTH_TYPE } from '../../data/auth/constant';
import { AuthHeader } from '../../common/auth-header';
import { AuthPhotoLayout } from '../../common/auth_photo-layout';
import styled from 'styled-components';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <AuthContainer>
      <LayoutAppElem oneColumn>
        <AuthPhotoLayout>
          <GridElem spacing={6}>
            <AuthHeader />
            <LayoutElem size="small" direction="column" oneColumn>
              <AuthVerificationContainer type={AUTH_TYPE.EMAIL} />
            </LayoutElem>
          </GridElem>
        </AuthPhotoLayout>
      </LayoutAppElem>
    </AuthContainer>
  );
};

const AuthContainer = styled(PageElem)`
  height: 100vh;
  display: flex;
  align-items: center;
`;
