import React from 'react';
import { RouteComponentProps } from 'react-router';
import { LayoutElem } from '../../common/layout';
import { AuthRecoveryContainer } from '../../epic/auth-recovery';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { AuthHeader } from '../../common/auth-header';
import { GridElem } from '../../common/grid';
import { AuthPhotoLayout } from '../../common/auth_photo-layout';
import styled from 'styled-components';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <AuthContainer>
      <LayoutAppElem oneColumn>
        <AuthPhotoLayout>
          <GridElem>
            <AuthHeader />
            <LayoutElem size="small" direction="column" oneColumn>
              <AuthRecoveryContainer />
            </LayoutElem>
          </GridElem>
        </AuthPhotoLayout>
      </LayoutAppElem>
    </AuthContainer>
  );
};

const AuthContainer = styled(PageElem)`
  height: 100vh;
  display: flex;
  align-items: center;
`;
