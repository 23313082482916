import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const confirmAction = ({
  consultationId,
  payload,
}: {
  consultationId?: string;
  payload: {
    isCompleteClient?: boolean;
    isCompletePsycho?: boolean;
  };
}) => {
  console.log(consultationId, payload);
  return HttpRequest({
    method: API.PATCH.TYPE,
    url: API.PATCH.URL(consultationId),
    data: payload,
  });
};

export const getDateAction = ({
  consultationId,
}: {
  consultationId?: string;
}) => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL(consultationId),
  });
};
