import {
  ANIMAL_TYPE_ENUM,
  OPTION_INTER,
  PLACEMENT_ENUM,
  SPECIALIST_ENUM,
  TRANSPORTATION_ENUM,
} from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  // REQUEST_LIST = 'requestList',
  // REQUEST_CUSTOM = 'requestCustom',
  // LGBT_FRENDLY = 'lgbtFrendly',
  // METHOD_LIST = 'methodList',
  // METHOD_CUSTOM = 'methodCustom',
  // ABOUT_MY_PATH = 'aboutMyPath',
  // ABOUT_MY_SERVICE = 'aboutMyService',
  // URL = 'url',
  ANIMAL_TYPE_LIST = 'workAnimalType',
  SPECIALIST_LIST = 'specialistWith',
  TRANSPORTATION_LIST = 'transportation',
  PLACEMENT_LIST = 'placement',
  COMPATIBILITY_ANIMAL = 'ableCompatibilityAnimal',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  // [FORM_VALUE_ENUM.REQUEST_LIST]: string;
  // [FORM_VALUE_ENUM.REQUEST_CUSTOM]: string;
  // [FORM_VALUE_ENUM.LGBT_FRENDLY]: string;
  // [FORM_VALUE_ENUM.METHOD_LIST]: string;
  // [FORM_VALUE_ENUM.METHOD_CUSTOM]: string;
  // [FORM_VALUE_ENUM.ABOUT_MY_SERVICE]: string;
  // [FORM_VALUE_ENUM.URL]: string;
  [FORM_VALUE_ENUM.ANIMAL_TYPE_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.SPECIALIST_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.TRANSPORTATION_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.PLACEMENT_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]: string;
}

export const COMPATIBILITY_ANIMAL_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.YES'),
    value: 'true',
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.NO'),
    value: 'false',
  },
];

export const ANIMAL_TYPE_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.CAT'),
    value: ANIMAL_TYPE_ENUM.CAT,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.DOG'),
    value: ANIMAL_TYPE_ENUM.DOG,
  },
];
export const SPECIALIST_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.SPECIALIST.ANGRY'),
    value: SPECIALIST_ENUM.ANGRY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.SPECIALIST.BLIND'),
    value: SPECIALIST_ENUM.BLIND,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.SPECIALIST.DEAF'),
    value: SPECIALIST_ENUM.DEAF,
  },
];
export const TRANSPORTATION_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.TRANSPORTATION.CAR'),
    value: TRANSPORTATION_ENUM.CAR,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.TRANSPORTATION.BIKE'),
    value: TRANSPORTATION_ENUM.BIKE,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.TRANSPORTATION.WALK'),
    value: TRANSPORTATION_ENUM.WALK,
  },
];
export const PLACEMENT_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.PLACEMENT.OWNER'),
    value: PLACEMENT_ENUM.OWNER,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.PLACEMENT.CARETAKER'),
    value: PLACEMENT_ENUM.CARETAKER,
  },
];

export const API = {
  MAIN: {
    TYPE: 'PATCH',
    URL: 'user/data-update',
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/psychologist-data',
  },

  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string | null) => `file/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
