const toBoolean = (value: string | null | undefined): boolean | undefined => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return undefined;
};

export const convert = (values: any) => ({
  ...values,
  timezone: Number(values.timezone),
  medicationExperience: toBoolean(values?.medicationExperience),
});
