import React from 'react';
import { useDispatch, useSelector } from '../../lib/store';
import { useMutation } from 'react-query';
import { FormikValues, useFormik } from 'formik';
import { Component } from './component';
import { required } from '../../lib/validation/service';
import { validation } from '../../lib/validation';
import { action as fetch } from './action';
import { setAuthData } from '../../data/auth/action';
import { AUTH_MODULE_NAME } from '../../data/auth';
import {
  ACTION_ERROR_INTER,
  ACTION_RESPONSE_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';
import { useHistory } from 'react-router';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';

export const Container: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { state } = useSelector((s: any) => ({
    state: s[AUTH_MODULE_NAME],
  }));

  const initialValues = {
    [FORM_VALUE_ENUM.NAME_PET]: '',
    [FORM_VALUE_ENUM.CITY]: '',
    [FORM_VALUE_ENUM.TIME]: '',
    [FORM_VALUE_ENUM.SERVICE]: '',
    [FORM_VALUE_ENUM.REGION]: '',
    [FORM_VALUE_ENUM.DATE]: '',
  };

  const validate = (values: FormikValues) =>
    validation(values, {
      [FORM_VALUE_ENUM.NAME_PET]: [],
      [FORM_VALUE_ENUM.CITY]: [],
      [FORM_VALUE_ENUM.TIME]: [],
      [FORM_VALUE_ENUM.SERVICE]: [],
      [FORM_VALUE_ENUM.REGION]: [],
      [FORM_VALUE_ENUM.DATE]: [],
    });

  const onSuccess = (d: any) => {
    const data: ACTION_RESPONSE_INTER = d as unknown as ACTION_RESPONSE_INTER;

    dispatch(setAuthData(data.accessToken)).then(() => {
      history.push('/specialist-list?animalType=cat&date=10.10.2024');
    });
  };

  const action = useMutation(fetch, { onSuccess });

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      const queryParams = new URLSearchParams();

      if (values[FORM_VALUE_ENUM.NAME_PET]) {
        queryParams.append('animalType', values[FORM_VALUE_ENUM.NAME_PET]);
      }
      if (values[FORM_VALUE_ENUM.DATE]) {
        queryParams.append('date', values[FORM_VALUE_ENUM.DATE]);
      }
      if (values[FORM_VALUE_ENUM.CITY]) {
        queryParams.append('city', values[FORM_VALUE_ENUM.CITY]);
      }
      if (values[FORM_VALUE_ENUM.TIME]) {
        queryParams.append('time', values[FORM_VALUE_ENUM.TIME]);
      }
      if (values[FORM_VALUE_ENUM.SERVICE]) {
        queryParams.append('service', values[FORM_VALUE_ENUM.SERVICE]);
      }

      history.push(`/specialist-list?${queryParams.toString()}`);
    },
  });

  // const setFieldValue = (
  //   name: FORM_VALUE_TYPE,
  //   selectedOption: { value: string; label: string },
  // ) => {
  //   formik.setFieldValue(name, selectedOption.value);
  // };

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    return formik.setFieldValue(name, e?.value);
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    return action.isLoading || action.isSuccess || !formik.isValid; // Обновляем условие
  };

  const isLoading = () => action.isLoading;

  const isError = () => {
    return !!(action.isError && !action.isLoading && getErrorMessage());
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => {
    return formik.values[name];
  };

  const setValue = (
    options: { value: string; label: string }[],
    formikValueKey: FORM_VALUE_TYPE,
  ) => {
    if (!Array.isArray(options)) {
      console.error('options should be an array:', options);
      return null;
    }

    const optionList = options.find(
      (item) => item.value === formik.values[formikValueKey],
    );

    return optionList || null;
  };

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled()}
      getFieldValue={getFieldValue}
      formik={formik}
      isLoading={isLoading()}
      isError={isError()}
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
      setValue={setValue}
    />
  );
};
