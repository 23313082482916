import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDispatch, useSelector } from '../../lib/store';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_DATA_NAME,
  MODULE_NAME,
  USER_DATA_RAW,
} from './constant';
import { Component } from './component';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import {
  action as fetch,
  getAction,
  postPhotoAction,
  removeAction,
} from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  phoneOperator,
  phoneUA,
  required,
  requiredArray,
} from '../../lib/validation/service';
import {
  USER_ITEM_DATA_INTER,
  USER_ITEM_DATA_RAW_INTER,
} from '../../data/user/constant';
import { convertUser } from '../../data/user/convert';
import { convert } from './convert';
import { convertDate } from '../../lib/lang/service';

const config = {
  [FORM_VALUE_ENUM.NAME]: [required],
  [FORM_VALUE_ENUM.PHONE]: [phoneOperator, phoneUA, required],
  [FORM_VALUE_ENUM.AGE]: [required],
  [FORM_VALUE_ENUM.GENDER]: [],
  [FORM_VALUE_ENUM.TIMEZONE]: [required],
  [FORM_VALUE_ENUM.CITY]: [required],
  [FORM_VALUE_ENUM.COUNTRY]: [required],
  [FORM_VALUE_ENUM.LANGUAGE]: [required, requiredArray],
  [FORM_VALUE_ENUM.CONTACT_INFO]: [],

  [FORM_VALUE_ENUM.ANIMAL_NAME]: [required],
  [FORM_VALUE_ENUM.ANIMAL_GENDER]: [],
  [FORM_VALUE_ENUM.ANIMAL_AGE]: [],
  [FORM_VALUE_ENUM.ANIMAL_SIZE]: [required],
  [FORM_VALUE_ENUM.ANIMAL_TYPE]: [required],

  [FORM_VALUE_ENUM.NEED_GENDER]: [],
  [FORM_VALUE_ENUM.NEED_AGE]: [],
  [FORM_VALUE_ENUM.NEED_PLACEMENT]: [],
  [FORM_VALUE_ENUM.NEED_LANGUAGE]: [],
  [FORM_VALUE_ENUM.NEED_PREVIOUS_EXPERIENCE]: [],
  [FORM_VALUE_ENUM.NEED_SPECIALIST]: [],
  [FORM_VALUE_ENUM.NEED_COMPATIBILITY]: [],

  [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]: [required],
  [FORM_VALUE_ENUM.IMPORTANT_HABITS]: [],
  [FORM_VALUE_ENUM.CONCERNS]: [],
  [FORM_VALUE_ENUM.DIET]: [],
  [FORM_VALUE_ENUM.REQUIRE_CARE]: [],
  [FORM_VALUE_ENUM.MEDICATION]: [],
  [FORM_VALUE_ENUM.VACCINATION]: [required, requiredArray],

  // [FORM_VALUE_ENUM.REQUEST_LIST]: [requiredArray],
  // [FORM_VALUE_ENUM.REQUEST_CUSTOM]: [],
  // [FORM_VALUE_ENUM.LGBT_FRENDLY]: [],
  // [FORM_VALUE_ENUM.METHOD_LIST]: [requiredArray],
  // [FORM_VALUE_ENUM.METHOD_CUSTOM]: [],
  // [FORM_VALUE_ENUM.NEED_GENDER]: [],
  // [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: [],
  // [FORM_VALUE_ENUM.NEED_AGE]: [],
  // [FORM_VALUE_ENUM.ABOUT]: [],
};

const validate = (values: FormikValues) => validation(values, config);

export const Container: React.FC = () => {
  const [data, setData] = useState<USER_ITEM_DATA_INTER | undefined>(undefined);

  const query = useQueryClient();
  const dispatch = useDispatch();

  const action = useMutation((d: USER_DATA_RAW) => dispatch(fetch(d)), {
    onSuccess: () => {
      return query.invalidateQueries(MODULE_DATA_NAME);
    },
  });

  const {
    isSuccess: preFetchSuccess,
    isLoading: preFetchIsLoading,
    isError: preFetchIsError,
    refetch,
    error,
  } = useQuery(MODULE_NAME, getAction, {
    onSuccess: async (preFetch: any) => {
      const convertedData = convertUser(preFetch);
      setData(convertedData);
    },
  });

  const onRefetch = () => {
    refetch();
  };

  const deleteAction = useMutation(
    (id: string | null) => {
      return removeAction(id);
    },
    {
      onSuccess: () => {
        query.invalidateQueries(MODULE_DATA_NAME);
        query.invalidateQueries(MODULE_NAME);
      },
    },
  );

  // const getData = async (): Promise<USER_ITEM_DATA_INTER | undefined> => {
  //   const data =
  //     (await preFetchData?.data) as unknown as USER_ITEM_DATA_RAW_INTER;

  //   if (data) {
  //     return convertUser(data);
  //   }
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await getData();
  //     setData(result);
  //   };

  //   fetchData();
  // }, []);

  const onDelete = () => {
    if (data?.photo?.id) {
      const id = data.photo?.id;
      return deleteAction.mutate(id);
    }
    if (data?.animalPassportFile?.id) {
      const id = data.animalPassportFile?.id;
      return deleteAction.mutate(id);
    }
    if (data?.animalPhoto?.id) {
      const id = data.animalPhoto?.id;
      return deleteAction.mutate(id);
    }
  };

  const offsetInMinutes = new Date().getTimezoneOffset();
  const offsetInHours = -offsetInMinutes / 60;

  const initialValues = {
    [FORM_VALUE_ENUM.NAME]: data?.name || '',
    [FORM_VALUE_ENUM.PHONE]: data?.phone || '',
    [FORM_VALUE_ENUM.AGE]: data?.age || null,
    [FORM_VALUE_ENUM.GENDER]: data?.gender || null,
    [FORM_VALUE_ENUM.TIMEZONE]: data?.timezone || offsetInHours,
    [FORM_VALUE_ENUM.CITY]: data?.city || '',
    [FORM_VALUE_ENUM.COUNTRY]: data?.country || '',
    [FORM_VALUE_ENUM.LANGUAGE]: data?.language || [],
    [FORM_VALUE_ENUM.CONTACT_INFO]: data?.contactInfo || '',

    [FORM_VALUE_ENUM.ANIMAL_NAME]: data?.animalName || '',
    [FORM_VALUE_ENUM.ANIMAL_GENDER]: data?.animalGender || '',
    [FORM_VALUE_ENUM.ANIMAL_AGE]: data?.animalAge || null,
    [FORM_VALUE_ENUM.ANIMAL_SIZE]: data?.animalSize || null,
    [FORM_VALUE_ENUM.ANIMAL_TYPE]: data?.animalType || null,

    [FORM_VALUE_ENUM.NEED_GENDER]: data?.needGender || null,
    [FORM_VALUE_ENUM.NEED_AGE]: data?.needAge || null,
    [FORM_VALUE_ENUM.NEED_PLACEMENT]: data?.needPlacement || null,
    [FORM_VALUE_ENUM.NEED_LANGUAGE]: data?.needLanguage || [],
    [FORM_VALUE_ENUM.NEED_PREVIOUS_EXPERIENCE]:
      data?.needPreviousExperience || null,
    [FORM_VALUE_ENUM.NEED_SPECIALIST]: data?.specialistNeeded || [],
    [FORM_VALUE_ENUM.NEED_COMPATIBILITY]: data?.needCompatibility || null,

    [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]: data?.compatibilityAnimal || '',
    [FORM_VALUE_ENUM.IMPORTANT_HABITS]: data?.importantHabits || '',
    [FORM_VALUE_ENUM.CONCERNS]: data?.concerns || '',
    [FORM_VALUE_ENUM.DIET]: data?.diet || '',
    [FORM_VALUE_ENUM.REQUIRE_CARE]: data?.requireCare || '',
    [FORM_VALUE_ENUM.MEDICATION]: data?.medication || '',
    [FORM_VALUE_ENUM.VACCINATION]: data?.vaccination || [],
    // [FORM_VALUE_ENUM.REQUEST_LIST]: data?.requestList || [],
    // [FORM_VALUE_ENUM.REQUEST_CUSTOM]: data?.requestCustom || '',
    // [FORM_VALUE_ENUM.LGBT_FRENDLY]: data?.lgbtFrendly || null,

    // [FORM_VALUE_ENUM.METHOD_LIST]: data?.methodList || [],
    // [FORM_VALUE_ENUM.METHOD_CUSTOM]: data?.methodCustom || '',
    // [FORM_VALUE_ENUM.NEED_GENDER]: data?.needGender || null,
    // [FORM_VALUE_ENUM.NEED_AGE]: data?.needAge || null,
    // [FORM_VALUE_ENUM.ABOUT]: data?.about || '',
    // [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: data?.psychoCategory || '',
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      const convertedValues = convert(values);
      return action.mutate(convertedValues);
    },
    enableReinitialize: true,
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading || preFetchIsLoading || deleteAction.isLoading) {
      return true;
    }
  };

  const isUploading = () => {
    if (deleteAction.isLoading) {
      return true;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => {
    return formik.values[name];
  };

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, e.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    if (typeof formik.values[value] != 'object') {
      const optionList = options?.filter(
        (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
      )[0];
      return optionList ? optionList : null;
    }
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) =>
        item.value == formik.values[value]?.value,
    )[0];
    return optionList ? optionList : null;
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  const isLoading = () => {
    if (preFetchIsLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetchSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (preFetchIsError && !preFetchIsLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const preFetchError: ACTION_ERROR_INTER =
      action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
    if (preFetchError) {
      return preFetchError.message;
    }
  };
  return (
    <Component
      isSuccessDelete={deleteAction.isSuccess}
      onRefetch={onRefetch}
      photoUrl={data?.photo?.url}
      animalPhotoUrl={data?.animalPhoto?.url}
      animalPassportUrl={data?.animalPassportFile?.url}
      deleteAction={onDelete}
      onChangeSelect={onChangeSelect}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoading={isLoading()}
      isUploading={isUploading()}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
      isSuccessAction={action.isSuccess}
    />
  );
};
