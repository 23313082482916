import React from 'react';
import styled from 'styled-components';

import { CardElem } from '../../../common/card/index';
import { Spacing } from '../../../theme/service';
import { COLOR_DATA, COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text/index';
import {
  CHIP_STATUS_PAYMENT_COLOR_BG,
  CHIP_STATUS_PAYMENT_COLOR_TEXT,
  CONSULTATION_ITEM_DATA_INTER,
} from '../constant';
import { useHistory } from 'react-router';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { ChipElem } from '../../../common/chip/index';
import { GridElem } from '../../../common/grid/index';
import { AUTH_MODULE_NAME } from '../../../data/auth/index';
import { useSelector } from 'react-redux';
import { STORE_INTER, USER_ROLE } from '../../../data/auth/constant';
import { FlexElem } from '../../../common/flex/index';

export const Card: React.FC<CONSULTATION_ITEM_DATA_INTER> = ({
  id,
  psychologist,
  user,
  category,
  date,
  paymentStatus,
  time,
  duration,
}) => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const isUser = state.user?.role === USER_ROLE.USER;

  const href = `/consultation/${id}`;
  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };

  return (
    <>
      <CardStyled href={href} onClick={onClick}>
        {/* <FlexStyled> */}
        {!isUser && (
          <TextElem
            oneLine
            size="semiSmall"
            color="textPrimary"
            type="semi-bold"
            tid={user?.name ?? 'COMMON.NO_NAME'}
          />
        )}
        {isUser && (
          <TextElem
            oneLine
            size="semiSmall"
            color="textPrimary"
            type="bold"
            tid={psychologist?.name ?? 'COMMON.NO_NAME'}
          />
        )}
        {/* </FlexStyled> */}
        <TextElem
          oneLine
          size="semiSmall"
          type="semi-bold"
          color="textPrimary"
          tid={`${category}`}
        />
        <GridStyled>
          <TextElem
            size="semiSmall"
            color="textPlaceholder"
            tid={date}
            type="medium"
          />
          <TextElem
            size="semiSmall"
            color="textPlaceholder"
            tid="LANG.DATA.DATE_TVALUE"
            type="medium"
            tvalue={{ date: time }}
          />
        </GridStyled>
        <TextElem
          oneLine
          size="semiSmall"
          color="textPlaceholder"
          tid="CONSULTATION.LIST.DURATION"
          tvalue={{ value: duration }}
        />
        <Chip
          className="paymentStatus"
          tid={`CONSULTATION.PAYMENT.${paymentStatus.toUpperCase()}`}
          txColor={CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus]}
          bgColor={CHIP_STATUS_PAYMENT_COLOR_BG[paymentStatus]}
        />
      </CardStyled>
    </>
  );
};

const TextDesktop = styled(TextElem)`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const CardStyled = styled.a<{}>`
  display: grid;
  grid-column: span 5;
  grid-template-columns: subgrid;
  gap: 0;
  gap: inherit;
  // justify-content: start;
  // position: relative;
  align-items: center;
  // gap: ${Spacing(12)};
  padding: 0 ${Spacing(5)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: 14px;
  height: 56px;
  text-decoration: none;

  & > .paymentStatus {
    justify-self: end;
  }
`;

const FlexStyled = styled(FlexElem)`
  justify-content: space-between;
  height: 23px;
  width: 100%;

  @media screen and (min-width: 1025px) {
    & > .paymentStatusAdaptive {
      display: none;
    }
  }
`;

const GridStyled = styled(GridElem)`
  grid-template-columns: 1fr 1fr;
  gap: ${Spacing(4)};
  white-space: nowrap;

  @media screen and (max-width: 1024px) {
    grid-template-columns: auto 1fr;
  }
`;

const GridStyledModile = styled(GridStyled)`
  display: none;

  @media screen and (max-width: 1024px) {
    display: grid;
  }
`;

const Chip = styled(ChipElem)<{ bgColor: string; txColor: string }>`
  padding: 6px ${Spacing(3)};
  width: max-content;
  background: ${({ bgColor }) => bgColor && bgColor} !important;
  span {
    color: ${({ txColor }) => txColor && txColor} !important;
  }import { type } from './../../../theme/value';

`;
