import React from 'react';

import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import iconSetting from '../../asset/svg/menu-app/ring.svg';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Component: React.FC<{
  onSubmitForm: any;
}> = ({ onSubmitForm }) => {
  return (
    <Container onClick={onSubmitForm} spacing={3}>
      <img src={iconSetting} />
      <TextElem
        tid="SETTINGS.NOTIFICATION"
        color="textThird"
        type="semi-bold"
      />
    </Container>
  );
};

const Container = styled(GridElem)<{}>`
  padding: 17.5px;
  width: 100%;
  display: flex;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  align-items: center;
  position: relative;
  margin-top: auto;
  span {
    height: 15px;
  }

  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  }
`;
