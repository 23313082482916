export const MODULE_NAME = 'WELLCOME_HOME_FAG';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface Question {
  tid: string;
  answerTid: string;
}

export interface FAQProps {
  activeIcon: string;
  activeQuestion: number | null;
  toggleQuestion: (index: number) => void;
}

export const question: Question[] = [
  {
    tid: 'LANDING.HOME_FAQ.QUESTION_ONE',
    answerTid: 'LANDING.HOME_FAQ.ANSWER_ONE',
  },
  {
    tid: 'LANDING.HOME_FAQ.QUESTION_TWO',
    answerTid: 'LANDING.HOME_FAQ.ANSWER_TWO',
  },
  {
    tid: 'LANDING.HOME_FAQ.QUESTION_THREE',
    answerTid: 'LANDING.HOME_FAQ.ANSWER_THREE',
  },
  {
    tid: 'LANDING.HOME_FAQ.QUESTION_FOUR',
    answerTid: 'LANDING.HOME_FAQ.ANSWER_FOUR',
  },
  {
    tid: 'LANDING.HOME_FAQ.QUESTION_FIVE',
    answerTid: 'LANDING.HOME_FAQ.ANSWER_FIVE',
  },
  {
    tid: 'LANDING.HOME_FAQ.QUESTION_SIX',
    answerTid: 'LANDING.HOME_FAQ.ANSWER_SIX',
  },
  {
    tid: 'LANDING.HOME_FAQ.QUESTION_SEVEN',
    answerTid: 'LANDING.HOME_FAQ.ANSWER_SEVEN',
  },
];
