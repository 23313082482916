import { GENDER_ENUM, PLACEMENT_ENUM } from '../../data/user/constant';

export const MODULE_NAME = 'CARETAKER_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface PSYCHO_ITEM_DATA_RAW_INTER {
  id: string;
  name: string;
  age?: string | null;
  gender?: GENDER_ENUM | null;
  city: string;
  country: string;
  nationality?: string;
  profileDescription?: string | null;
  yearExperience?: number;
  timezone?: number;
  minPrice?: number;
  price?: number;
  photo?: {
    name: string;
    id: string;
    url: string;
  };
}

export interface PSYCHO_ITEM_LIST_DATA_RAW_INTER {
  list: PSYCHO_ITEM_DATA_RAW_INTER[];
  isEmpty: boolean;
}

export interface ACTION_RESPONSE_INTER
  extends PSYCHO_ITEM_LIST_DATA_RAW_INTER {}

export interface PSYCHO_ITEM_DATA_INTER
  extends Omit<PSYCHO_ITEM_DATA_RAW_INTER, 'timezone'> {
  timezone: string;
}

export interface DATA_INTER {
  list: PSYCHO_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export interface filterOption {
  caretakerCategory: number;
  age: number;
  placement: PLACEMENT_ENUM;
  ableCompatibilityAnimal: string;
  medicationExperience: string;
  gender: GENDER_ENUM;
  city: string;
  workAnimalType: string;
  specialistWith: string;
  date: Date;
  time: string;
  price: number;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `user/caretaker/list${params}`,
};
