import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { ACTION_ERROR_INTER } from './constant';
import { Component } from './component';
import { removeAction } from './action';
import { PROFILE_PSYCHO_LIST_CATEGORY_MODULE_NAME } from '../profile-psycho-category-list';
import { action as fetch } from './action';
import {
  FILE_STATE_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
} from '../profile-psycho-create-category/constant';
import { required } from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { FILE_UPLOAD_INTER } from '../../data/file/constant';

export const config = {
  [FORM_VALUE_ENUM.CATEGORY]: [required],
  [FORM_VALUE_ENUM.PRICE]: [required],
  [FORM_VALUE_ENUM.REQUIRE_HOURS]: [required],
  [FORM_VALUE_ENUM.OVER_NIGHT_AVAILABILITY]: [required],
  [FORM_VALUE_ENUM.FULL_DAY_AVAILABILITY]: [required],
  [FORM_VALUE_ENUM.DESCRIPTION]: [],
};

const validate = (values: FormikValues) => validation(values, config);

export const Container: React.FC<{
  data: any;
  onSuccess?: Function;
  onToggle: () => void;
  isOpen: boolean;
}> = ({ data, onSuccess, onToggle, isOpen }) => {
  const queryClient = useQueryClient();

  // const [diploma, setDiploma] = useState<
  //   (FILE_STATE_INTER | FILE_UPLOAD_INTER)[]
  // >([...data.diploma]);

  // const [certificate, setCertificate] = useState<
  //   (FILE_STATE_INTER | FILE_UPLOAD_INTER)[]
  // >([...data.certificate]);

  // const [video, setVideo] = useState<File | FILE_UPLOAD_INTER | null>(
  //   data?.video ? data.video : null,
  // );

  // const [addDiplomaCount, setAddDiplomaCount] = useState(
  //   data.diploma.length > 0 ? 0 : 1,
  // );
  // const [addCertificateCount, setAddCertificateCount] = useState(
  //   data.certificate.length > 0 ? 0 : 1,
  // );

  const initialValues = {
    [FORM_VALUE_ENUM.CATEGORY]: data?.category,
    [FORM_VALUE_ENUM.PRICE]: data?.price,
    [FORM_VALUE_ENUM.REQUIRE_HOURS]: data?.requireHours,
    [FORM_VALUE_ENUM.OVER_NIGHT_AVAILABILITY]:
      data?.overNightAvailability === null
        ? ''
        : String(data?.overNightAvailability),
    [FORM_VALUE_ENUM.FULL_DAY_AVAILABILITY]:
      data?.fullDayAvailability === null
        ? ''
        : String(data.fullDayAvailability),
    [FORM_VALUE_ENUM.DESCRIPTION]: data?.description,
  };

  const action = useMutation(fetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(PROFILE_PSYCHO_LIST_CATEGORY_MODULE_NAME);
      // setAddDiplomaCount(0);
      // setAddCertificateCount(0);
      onSuccess && onSuccess();
    },
  });

  const handleSubmit = async () => {
    const formData = new FormData();
    // const diplomaId: string[] = [];
    // const certificateId: string[] = [];

    formData.append(
      FORM_VALUE_ENUM.CATEGORY,
      formik.values[FORM_VALUE_ENUM.CATEGORY],
    );

    formData.append(
      FORM_VALUE_ENUM.PRICE,
      formik.values[FORM_VALUE_ENUM.PRICE],
    );

    if (data?.id) {
      formData.append('id', data?.id);
    }

    formData.append(
      FORM_VALUE_ENUM.DESCRIPTION,
      formik.values[FORM_VALUE_ENUM.DESCRIPTION],
    );

    // if (diploma?.length > 0) {
    //   diploma.forEach((file) => {
    //     if ('file' in file) {
    //       formData.append(`diploma`, file.file);
    //     } else {
    //       diplomaId.push(file.id);
    //     }
    //   });
    // }

    // if (certificate?.length > 0) {
    //   certificate.forEach((file) => {
    //     if ('file' in file) {
    //       formData.append(`certificate`, file.file);
    //     } else {
    //       certificateId.push(file.id);
    //     }
    //   });
    // }

    // if (video) {
    //   if (video instanceof File) {
    //     formData.append('video', video);
    //   }
    // }

    // formData.append('diplomaId', JSON.stringify(diplomaId));
    // formData.append('certificateId', JSON.stringify(certificateId));

    const payload = {
      id: data.id,
      category: formik.values[FORM_VALUE_ENUM.CATEGORY],
      price: formik.values[FORM_VALUE_ENUM.PRICE],
      requireHours: formik.values[FORM_VALUE_ENUM.REQUIRE_HOURS],
      fullDayAvailability: formik.values[FORM_VALUE_ENUM.FULL_DAY_AVAILABILITY],
      overNightAvailability:
        formik.values[FORM_VALUE_ENUM.OVER_NIGHT_AVAILABILITY],
      description: formik.values[FORM_VALUE_ENUM.DESCRIPTION],
    };

    action.mutate({ payload });
  };

  const formik: FormikValues = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: () => {},
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  // const isDisabledButton = diploma?.length === 0 || certificate?.length === 0;

  const isSubmitDisabled = () => {
    // if (diploma?.length === 0) {
    //   return true;
    // }

    // if (certificate?.length === 0) {
    //   return true;
    // }

    // if (isDisabledButton) {
    //   return true;
    // }
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }

    if (deleteAction.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (
    options: { value: number; label: string }[],
    value: any,
  ) => {
    const optionList = options?.filter(
      (item: any) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const deleteAction = useMutation((id: string) => removeAction(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(PROFILE_PSYCHO_LIST_CATEGORY_MODULE_NAME);
      onSuccess && onSuccess();
    },
  });

  const handleDelete = () => {
    deleteAction.mutate(data?.id);
  };

  // const onDeleteVideo = () => {
  //   setVideo(null);
  // };

  // const setVideoState = (e: File | FILE_UPLOAD_INTER) => {
  //   setVideo(e);
  // };

  // const setDiplomaState = (file: FILE_STATE_INTER) => {
  //   setDiploma((prev) => [...prev, file]);
  // };

  // const onDeleteDiploma = (id: string) => {
  //   setDiploma((prev) => prev.filter((file) => file.id !== id));
  // };

  // const setCertificateState = (file: FILE_STATE_INTER) => {
  //   setCertificate((prev) => [...prev, file]);
  // };

  // const onDeleteCertificate = (id: string) => {
  //   setCertificate((prev) => prev.filter((file) => file.id !== id));
  // };

  return (
    <Component
      isSuccessDelete={deleteAction.isSuccess}
      handleDelete={handleDelete}
      // setCertificateState={setCertificateState}
      // onDeleteCertificate={onDeleteCertificate}
      // onDeleteDiploma={onDeleteDiploma}
      // setDiplomaState={setDiplomaState}
      // setVideoState={setVideoState}
      // onDeleteVideo={onDeleteVideo}
      data={data}
      onToggle={onToggle}
      isOpen={isOpen}
      isLoading={isLoading()}
      setFieldValue={setFieldValue}
      getFieldError={getFieldError}
      isFieldError={isFieldError}
      setValue={setValue}
      getFieldValue={getFieldValue}
      formik={formik}
      handleSubmit={handleSubmit}
      isSubmitDisabled={isSubmitDisabled}
      isError={isError()}
      getErrorMessage={getErrorMessage}
      isSuccess={isSuccess()}
      // addDiplomaCount={addDiplomaCount}
      // setAddDiplomaCount={setAddDiplomaCount}
      // addCertificateCount={addCertificateCount}
      // setAddCertificateCount={setAddCertificateCount}
      // isAddDiplomaDisabled={addDiplomaCount + diploma.length >= 6}
      // isAddCertificateDisabled={addCertificateCount + certificate.length >= 6}
    />
  );
};
