import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { USER_ROLE } from '../../data/auth/constant';
import { CopyElem } from '../../common/copy';
import copyIcon from '../../asset/svg/alert/copy.svg';
import { ButtonElem } from '../../common/button';
import successChip from '../../asset/svg/common/tick-circle.svg';
import errorChip from '../../asset/svg/common/close-circle.svg';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  role?: USER_ROLE;
  isStartConsultation: boolean;
  isTimeSuccess: boolean;
  // url: string;
  isCompleteClient: boolean;
  isCompletePsycho: boolean;
  handleSuccess: () => void;
  isPaid?: boolean;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  role,
  isStartConsultation,
  // url,
  isTimeSuccess,
  isCompleteClient,
  isCompletePsycho,
  handleSuccess,
  isPaid,
}) => {
  const isPsychoButton = role === USER_ROLE.CARETAKER;
  const isClientButton = role === USER_ROLE.USER;
  const textUserAnswer = 'CONSULTATION.CONFIRM.USER_ANSWER';

  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="CONSULTATION.CONFIRM.SUCCESS" />
      )}
      <BlockWrapper>
        {/* <Block>
          {!isStartConsultation && (
            <InfoMessageDesktop>
              <Title
                tid="CONSULTATION.NOT_START"
                size="semiSmall"
                color="textSecondary"
              />
            </InfoMessageDesktop>
          )}

          {isStartConsultation && (
            <>
              <TextElem
                size="input"
                color="textSecondary"
                tid="CONSULTATION.URL_TITLE"
              />
              <CopyElem value={url}>
                <LinkMessage>
                  <TextElem tid={url} size="semiSmall" color="default" />
                  <CopyIconStyled src={copyIcon} alt="copy" /> 
                </LinkMessage>
              </CopyElem>
            </>
          )}
        </Block> */}

        {isTimeSuccess && isPaid && isStartConsultation && (
          <Block>
            <ConfirmContainer>
              <Title
                tid="CONSULTATION.CONFIRM.TITLE"
                color="textPrimary"
                size="semiSmall"
              />
              <ButtonContainer>
                {!isCompletePsycho ? (
                  isPsychoButton ? (
                    <ButtonElemStyled
                      onClick={handleSuccess}
                      type="submit"
                      tid="CONSULTATION.CONFIRM.BUTTON"
                    />
                  ) : (
                    <ChipError>
                      <TextElemLong
                        oneLine
                        color="error"
                        size="semiSmall"
                        tid="CONSULTATION.CONFIRM.PSYCHOLOGIST_NO_ANSWER"
                      />
                      <TextElemShort
                        oneLine
                        color="error"
                        size="semiSmall"
                        tid="CONSULTATION.CONFIRM.PSYCHOLOGIST_NO_ANSWER_SHORT"
                      />
                      <img alt="success" src={errorChip} />
                    </ChipError>
                  )
                ) : (
                  <ChipSuccess>
                    <TextElem
                      color="success"
                      size="semiSmall"
                      tid={
                        !isPsychoButton
                          ? 'CONSULTATION.CONFIRM.PSYCHOLOGIST_ANSWER'
                          : textUserAnswer
                      }
                    />
                    <img alt="success" src={successChip} />
                  </ChipSuccess>
                )}
                {!isCompleteClient ? (
                  isClientButton ? (
                    <ButtonElemStyled
                      onClick={handleSuccess}
                      tid="CONSULTATION.CONFIRM.BUTTON"
                    />
                  ) : (
                    <ChipError>
                      <TextElemLong
                        oneLine
                        color="error"
                        size="semiSmall"
                        tid="CONSULTATION.CONFIRM.USER_NO_ANSWER"
                      />
                      <TextElemShort
                        oneLine
                        color="error"
                        size="semiSmall"
                        tid="CONSULTATION.CONFIRM.USER_NO_ANSWER_SHORT"
                      />
                      <img alt="success" src={errorChip} />
                    </ChipError>
                  )
                ) : (
                  <ChipSuccess>
                    <TextElem
                      color="success"
                      size="semiSmall"
                      tid={
                        !isClientButton
                          ? 'CONSULTATION.CONFIRM.CLIENT_ANSWER'
                          : textUserAnswer
                      }
                    />
                    <img alt="success" src={successChip} />
                  </ChipSuccess>
                )}
              </ButtonContainer>
            </ConfirmContainer>
          </Block>
        )}
      </BlockWrapper>
    </>
  );
};

const BlockWrapper = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  width: 100%;

  @media screen and (max-width: 1350px) {
    flex-direction: column;
  }
`;

const TextElemShort = styled(TextElem)`
  display: none;

  @media screen and (max-width: 1160px) {
    display: block;
  }
`;

const TextElemLong = styled(TextElem)`
  display: block;

  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

const Title = styled(TextElem)`
  @media screen and (max-width: 720px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px;
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: ${Spacing(4)} ${Spacing(6)} !important;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  div {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600 !important;
      white-space: nowrap;
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px !important;
      color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
    }
  }
`;

const Chip = styled.div`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  padding: ${Spacing(3)} 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing(2)};
`;

const ChipSuccess = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
`;

const ChipError = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.ERROR]};
`;

const ButtonContainer = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 1350px) {
    width: 100%;
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

const ConfirmContainer = styled.div`
  display: flex;
  gap: ${Spacing(4)};
  align-items: start;
  flex-direction: column;
  width: 100%;
`;

const CopyIconStyled = styled.img`
  width: 16px !important;
  height: 16px !important;
`;

const InfoMessageDesktop = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  padding: 15px;

  span {
    line-height: 1em;
  }

  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

const InfoMessageMobile = styled.div`
  display: none;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  padding: 17px;
  gap: ${Spacing(3)};

  span {
    line-height: 1em;
  }

  @media screen and (max-width: 1160px) {
    display: flex;
  }
`;

const LinkMessage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  padding: 15px;

  span {
    text-decoration: underline;
    line-height: 1em;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${Spacing(4)};
  justify-content: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  width: 100%;

  @media screen and (max-width: 1160px) {
    padding: ${Spacing(5)};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  }
`;

const BlockMobile = styled.div`
  display: none;
  flex-direction: column;
  align-items: start;
  gap: ${Spacing(3)};
  justify-content: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(5)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  width: 100%;

  @media screen and (max-width: 1160px) {
    display: flex;
  }
`;
