import React from 'react';

import styled, { css } from 'styled-components';

import {
  CONSULTATION_STATUS_OPTION,
  FORM_VALUE_ENUM,
  PAYMENT_STATUS_OPTION,
} from './constant';
import { SelectElem } from '../../common/select';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
// import { FieldDateInputElem } from '../../common/field-data-input/index';
import { i18n } from '../../lib/lang/core';
// import consultationIcon from '../../asset/svg/menu-app/consultation.svg';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import arrowSvg from '../../asset/svg/button/arrow-down.svg';
import arrowExpandedSvg from '../../asset/svg/button/arrow-down-expanded.svg';
import { FieldDataTimeInputElem } from '../../common/field-datetime-input';
import { FieldDateInputElem } from '../../common/field-data-input';

export const Component: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  onChangeSelect: (name: string, values: any) => void;
  handleSelectUserChange: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  setFieldValue: Function;
  setValue: Function;
  setDate: Function;
  date: [string, string] | [];
  setIndentify: Function;
  isOpenFilter: boolean;
  toggleOpenFilter: Function;
  userList: {
    label: string;
    value: string;
  }[];
}> = ({
  isFieldError,
  getFieldError,
  onChangeSelect,
  getFieldValue,
  isLoading,
  setFieldValue,
  handleSelectUserChange,
  setValue,
  setDate,
  setIndentify,
  date,
  userList,
  isOpenFilter,
  toggleOpenFilter,
}) => {
  const onChangeDate = (_first: any, second: any) => {
    setDate(second);
  };
  const onChangeUser = (
    name: any,
    data: {
      label: string;
      value: string;
    },
  ) => {
    setIndentify(data?.value);
    setFieldValue(name, data);
  };

  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected?.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };

  return (
    <Container>
      {/* <HeaderContainer>
        <img src={consultationIcon} alt="header logo" />
        <TextElem
          color="textPrimary"
          type="bold"
          size="headerLogo"
          tid="CONSULTATION.HEADER"
        />
      </HeaderContainer> */}

      <SelectContainer>
        <StyledSelect
          backgroundColor="white"
          isClearable
          dynamic
          name={FORM_VALUE_ENUM.USERS}
          filterOption={() => true}
          onInputChange={handleSelectUserChange}
          placeholder="CONSULTATION.FILTER.USERS"
          options={userList}
          errorMessage={getFieldError(FORM_VALUE_ENUM.USERS)}
          error={isFieldError(FORM_VALUE_ENUM.USERS)}
          // value={getFieldValue(FORM_VALUE_ENUM.USERS)}
          value={setValue(userList, FORM_VALUE_ENUM.USERS)}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          onChange={onChangeUser}
        />

        <StyledSelect
          backgroundColor="white"
          placeholder="CONSULTATION.FILTER.CHOOSE_STATUS"
          isMulti
          isClearable
          filterOption={() => true}
          name={FORM_VALUE_ENUM.CONSULTATION_STATUS}
          options={CONSULTATION_STATUS_OPTION}
          errorMessage={getFieldError(FORM_VALUE_ENUM.CONSULTATION_STATUS)}
          error={isFieldError(FORM_VALUE_ENUM.CONSULTATION_STATUS)}
          value={getFieldValue(FORM_VALUE_ENUM.CONSULTATION_STATUS)}
          onChange={onChangeSelect}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          customComponents={{ MultiValueContainer: multiValueContainer }}
          // isDisabled={isLoading}
        />

        <StyledSelect
          isClearable
          backgroundColor="white"
          placeholder="CONSULTATION.FILTER.PAYMENT_STATUS"
          filterOption={() => true}
          name={FORM_VALUE_ENUM.PAYMENT_STATUS}
          options={PAYMENT_STATUS_OPTION}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PAYMENT_STATUS)}
          error={isFieldError(FORM_VALUE_ENUM.PAYMENT_STATUS)}
          value={setValue(
            PAYMENT_STATUS_OPTION,
            FORM_VALUE_ENUM.PAYMENT_STATUS,
          )}
          onChange={setFieldValue}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          // isDisabled={isLoading}
        />
        <FielDateInputElemStyled
          maxWidth="255px"
          minWidth="255px"
          placeholder={[
            i18n.t('CONSULTATION.CALENDAR.START'),
            i18n.t('CONSULTATION.CALENDAR.END'),
          ]}
          name={FORM_VALUE_ENUM.DATE}
          onChange={onChangeDate}
        />
      </SelectContainer>
      <SelectContainerMobile isExpanded={isOpenFilter}>
        <FlexContainer onClick={() => toggleOpenFilter()}>
          <TextElem
            type="semi-bold"
            color="textButtonClick"
            size="semiSmall"
            tid="CONSULTATION.FILTER.TITLE"
          />
          <ArrowImg
            className="arrow"
            isExpanded={isOpenFilter}
            src={isOpenFilter ? arrowExpandedSvg : arrowSvg}
            alt="arrow"
          />
        </FlexContainer>
        <SelectInputContainer isExpanded={isOpenFilter}>
          <StyledSelectMobile
            max-height="48px"
            min-height="48px"
            isClearable
            dynamic
            filterOption={() => true}
            onInputChange={handleSelectUserChange}
            placeholder="CONSULTATION.FILTER.USERS"
            options={userList}
            errorMessage={getFieldError(FORM_VALUE_ENUM.USERS)}
            error={isFieldError(FORM_VALUE_ENUM.USERS)}
            // value={getFieldValue(FORM_VALUE_ENUM.USERS)}
            value={setValue(userList, FORM_VALUE_ENUM.USERS)}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            name={FORM_VALUE_ENUM.USERS}
            onChange={onChangeUser}
            // isDisabled={isLoading}
          />

          <StyledSelectMobile
            isMulti
            placeholder="CONSULTATION.FILTER.CHOOSE_STATUS"
            filterOption={() => true}
            isClearable
            name={FORM_VALUE_ENUM.CONSULTATION_STATUS}
            options={CONSULTATION_STATUS_OPTION}
            errorMessage={getFieldError(FORM_VALUE_ENUM.CONSULTATION_STATUS)}
            error={isFieldError(FORM_VALUE_ENUM.CONSULTATION_STATUS)}
            value={getFieldValue(FORM_VALUE_ENUM.CONSULTATION_STATUS)}
            onChange={onChangeSelect}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            customComponents={{ MultiValueContainer: multiValueContainer }}
            // isDisabled={isLoading}
          />

          <StyledSelectMobile
            isClearable
            placeholder="CONSULTATION.FILTER.PAYMENT_STATUS"
            filterOption={() => true}
            name={FORM_VALUE_ENUM.PAYMENT_STATUS}
            options={PAYMENT_STATUS_OPTION}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PAYMENT_STATUS)}
            error={isFieldError(FORM_VALUE_ENUM.PAYMENT_STATUS)}
            value={setValue(
              PAYMENT_STATUS_OPTION,
              FORM_VALUE_ENUM.PAYMENT_STATUS,
            )}
            onChange={setFieldValue}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            // isDisabled={isLoading}
          />
          <FielDateInputElemStyledMobile
            placeholder={[
              i18n.t('CONSULTATION.CALENDAR.START'),
              i18n.t('CONSULTATION.CALENDAR.END'),
            ]}
            name={FORM_VALUE_ENUM.DATE}
            onChange={onChangeDate}
          />
        </SelectInputContainer>
      </SelectContainerMobile>
    </Container>
  );
};

const SelectInputContainer = styled.div<{ isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const ArrowImg = styled.img<{ isExpanded: boolean }>`
  width: 20px;
  height: 20px;
  margin-left: ${Spacing(2)};
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const SelectContainerMobile = styled.div<{ isExpanded: boolean }>`
  display: none;
  gap: ${Spacing(3)};
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  padding: ${Spacing(4)};
  height: ${({ isExpanded }) => (isExpanded ? 'auto' : Spacing(12))};
  width: 100%;

  @media screen and (max-width: 1160px) {
    display: flex;
  }
`;

const FielDateInputElemStyledMobile = styled(FieldDateInputElem)<{
  isOpen?: boolean;
}>`
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  border-color: ${({ theme }) =>
    theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  .ant-picker-panel {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-input > input::placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
  }

  .ant-picker-focused {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-footer {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner {
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: none;
    `}
`;

const FielDateInputElemStyled = styled(FieldDateInputElem)<{
  isOpen?: boolean;
}>`
  .ant-picker-panel {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-focused {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-footer {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner {
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
  }

  @media screen and (max-width: 1160px) {
    width: 100%;
    max-width: none;
    min-width: none;
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;

    ${({ isOpen }) =>
      !isOpen &&
      css`
        display: none;
      `}
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 166px;
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;

  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  // max-width: 830px;
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  @media screen and (max-width: 1160px) {
    gap: ${Spacing(2)};
    grid-template-columns: 1fr;
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    width: unset;
  }

  // @media screen and (max-width: 1400px) {
  //   width: unset;
  // }
`;

const StyledSelectMobile = styled(SelectElem)`
  & .Select__control {
    width: 100%;
    min-height: 48px;
    max-height: 48px;
  }
`;

const StyledSelect = styled(SelectElem)<{
  width?: string;
  isOpen?: boolean;
  backgroundColor?: string;
}>`
  & .Select__control {
    width: 100%;
    min-height: 48px;
    max-height: 48px;

    input {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]} !important;
    }

    @media screen and (max-width: 1160px) {
      background-color: ${({ theme }) =>
        theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
    }
  }

  .Select__value-container--is-multi {
    max-width: calc(100% - 5px) !important;
  }
  max-width: ${({ width }) => (width ? width : '186px')};
  min-width: ${({ width }) => (width ? width : '186px')};

  @media screen and (max-width: 1160px) {
    width: 100%;
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    max-width: unset;
    min-width: none;

    ${({ isOpen }) =>
      !isOpen &&
      css`
        display: none;
      `}
  }

  @media screen and (max-width: 1130px) {
    max-width: 150px;
    min-width: unset;
  }

  @media screen and (max-width: 1000px) {
    max-width: 120px;
    min-width: unset;
  }
`;

const Container = styled.div`
  width: 100%;
  // max-width: 1130px;
  // height: 56px;
  // padding: 0 24px 24px 24px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  // margin-top: ${Spacing(6)};
  // margin-bottom: ${Spacing(5)};
  align-self: end;
  align-items: center;

  // @media screen and (max-width: 1400px) {
  //   justify-content: center;
  // }

  // @media screen and (max-width: 1160px) {
  //   padding: 0 ${Spacing(4)};
  // }
`;
