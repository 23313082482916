import React, { useState } from 'react';
import styled from 'styled-components';

import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { SkeletonListElem } from '../../common/skeleton-list';
import { ProfilePsychoCreateСategoryContainer } from '../profile-psycho-create-category';
import { ProfilePsychoUpdateCategoryContainer } from '../profile-psycho-update-category';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  list: any;
  resetForm: Function;
  isCreateForm: boolean;
  setIsCreateForm: Function;
  preFetchIsLoading: boolean;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  setIsCreateForm,
  list,
  isCreateForm,
  resetForm,
  preFetchIsLoading,
}) => {
  const [openFormIndex, setOpenFormIndex] = useState<number | null>(null);

  const handleToggleForm = (index: number) => {
    if (openFormIndex === index) {
      setOpenFormIndex(null);
    } else {
      setOpenFormIndex(index);
      setIsCreateForm(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderElem />}
      <Container>
        <ButtonElem
          onClick={() => {
            setIsCreateForm(true);
            setOpenFormIndex(null);
          }}
          tid="CATEGORY.LIST.ADD"
          type="add"
          disabled={isLoading || isCreateForm || preFetchIsLoading}
        />

        {preFetchIsLoading && <SkeletonListElem />}

        {isCreateForm && (
          <ProfilePsychoCreateСategoryContainer
            isOpen={isCreateForm}
            onToggle={() => setIsCreateForm(false)}
            onSuccess={resetForm}
          />
        )}
        {list?.length > 0 &&
          list.map((e: any, index: number) => (
            <ProfilePsychoUpdateCategoryContainer
              data={e}
              key={index}
              // onSuccess={() => setOpenFormIndex(null)}
              onToggle={() => handleToggleForm(index)}
              isOpen={openFormIndex === index}
            />
          ))}
      </Container>
      {isError && <AlertActionElem text={errorMessage} />}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;
