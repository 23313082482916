import React, { useState } from 'react';
import styled from 'styled-components';

import { TextElem } from '../../common/text';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { FileUploaderElem } from '../../common/file-uploader';
import { ButtonElem } from '../../common/button';
import { SkeletonFormElem } from '../../common/skeleton-form';
import { FileItemUploadContainer } from '../file-item-upload';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  videoIsLoading?: boolean;
  handleDelete: Function;
  data: any;
  preFetchSuccess: boolean;
  preFetchIsLoading: boolean;
  onRefetch: () => void;
  isSuccess: boolean;
}> = ({
  isLoading,
  isError,
  errorMessage,
  handleDelete,
  data,
  preFetchSuccess,
  preFetchIsLoading,
  onRefetch,
  isSuccess,
}) => {
  const photoObj = data?.photo ? data.photo : null;

  return (
    <>
      {isSuccess && <AlertActionElem type="success" tid="USER.FILE.DELETE" />}
      {isLoading && <LoaderElem />}
      {preFetchIsLoading && <SkeletonFormElem />}
      {preFetchSuccess && (
        <Container>
          <TextElem
            tid="USER.PSYCHOLOGIST.FILE_INFORMATION"
            type="medium"
            size="main"
            color="buttonText"
          />
          <FileItemUploadContainer
            type="photo"
            defaultValue={photoObj?.url}
            onSuccess={onRefetch}
            onReset={() => {
              handleDelete(photoObj?.id);
              onRefetch();
            }}
            isDot
            title="COMMON.FILE.IMAGE_TITLE"
          />
          {isError && <AlertActionElem text={errorMessage} />}
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
`;
