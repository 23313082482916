import React from 'react';

import styled, { css } from 'styled-components';

import { FORM_VALUE_ENUM } from './constant';
import { SelectElem } from '../../common/select';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { i18n } from '../../lib/lang/core';
import consultationIcon from '../../asset/svg/menu-app/consultation.svg';
import { COLOR_ENUM } from '../../theme/color';
import arrowSvg from '../../asset/svg/button/arrow-down.svg';
import arrowExpandedSvg from '../../asset/svg/button/arrow-down-expanded.svg';
import { USER_ROLE_OPTION_LIST } from '../../data/user/constant';
import searchIcon from '../../asset/svg/common/search.svg';
import Select from 'react-select/dist/declarations/src/Select';
import { FieldTextElem } from '../../common/field-text';
import { FormikValues } from 'formik';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  onChangeSelect: (name: string, values: any) => void;
  // handleSelectUserChange: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  setFieldValue: Function;
  setValue: Function;
  setIndentify: Function;
  // userList: {
  //   label: string;
  //   value: string;
  // }[];
}> = ({
  formik,
  isFieldError,
  getFieldError,
  onChangeSelect,
  getFieldValue,
  isLoading,
  setFieldValue,
  // handleSelectUserChange,
  setValue,
  setIndentify,
  // userList,
}) => {
  // const onChangeUser = (
  //   name: any,
  //   data: {
  //     label: string;
  //     value: string;
  //     email: string;
  //     phone: string;
  //   },
  // ) => {n
  //   setIndentify(data?.value);
  //   setFieldValue(name, { label: name, value: data?.label });
  // };
  const onChangeUser = (e: any) => {
    setIndentify(e.target.value);
    setFieldValue(FORM_VALUE_ENUM.USERS, e.target.value);
  };

  return (
    <Container>
      <SelectContainer>
        <StyledFieldText
          name={FORM_VALUE_ENUM.USERS}
          onChange={onChangeUser}
          onBlur={formik.handleBlur}
          placeholder="USER.FILTER.SEARCH"
          value={getFieldValue(FORM_VALUE_ENUM.USERS)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.USERS)}
          error={isFieldError(FORM_VALUE_ENUM.USERS)}
        />
        {/* <StyledSelectSearch
          width="250px"
          isClearable
          dynamic
          filterOption={() => true}
          onInputChange={handleSelectUserChange}
          placeholder="USER.FILTER.SEARCH"
          options={userList}
          errorMessage={getFieldError(FORM_VALUE_ENUM.USERS)}
          error={isFieldError(FORM_VALUE_ENUM.USERS)}
          value={setValue(userList, FORM_VALUE_ENUM.USERS)}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          name={FORM_VALUE_ENUM.USERS}
          onChange={onChangeUser}
          backgroundColor='white'
        /> */}

        <StyledSelect
          isClearable
          width="250px"
          placeholder="USER.FILTER.ROLE"
          name={FORM_VALUE_ENUM.ROLE}
          options={USER_ROLE_OPTION_LIST}
          errorMessage={getFieldError(FORM_VALUE_ENUM.ROLE)}
          error={isFieldError(FORM_VALUE_ENUM.ROLE)}
          value={setValue(USER_ROLE_OPTION_LIST, FORM_VALUE_ENUM.ROLE)}
          onChange={setFieldValue}
          backgroundColor="white"
        />
      </SelectContainer>
    </Container>
  );
};

const SelectContainer = styled.div`
  width: 100%;
  // max-width: 830px;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  @media screen and (max-width: 900px){
    grid-template-columns 1fr;
  }

`;

const StyledFieldText = styled(FieldTextElem)`
  input {
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    &:hover:not(:focus) {
      background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    }
    &:focus-within {
      background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    }
    border: none;
  }
`;

const StyledSelectSearch = styled(SelectElem)<{
  width?: string;
}>`
  & .Select__control {
    width: 100%;

    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
    position: relative;
    padding-left: 40px;
  }

  & .Select__control::before {
    content: '';
    position: absolute;
    top: 15px;
    left: ${Spacing(4)};
    width: 18px;
    height: 18px;
    background-image: url(${searchIcon});
    background-size: cover;
    pointer-events: none;
  }

  max-width: ${({ width }) => (width ? width : '186px')};
  min-width: ${({ width }) => (width ? width : '186px')};

  @media screen and (max-width: 900px) {
    max-width: none;
    min-width: none;
  }
`;

const StyledSelect = styled(SelectElem)<{ width?: string }>`
  & .Select__control {
    width: 100%;
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
  }

  // & .Select__control--menu-is-open {
  //   background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
  // }

  & .Select__menu {
    z-index: 6;
  }

  max-width: ${({ width }) => (width ? width : '186px')};
  min-width: ${({ width }) => (width ? width : '186px')};

  @media screen and (max-width: 900px) {
    max-width: none;
    min-width: none;
  }
`;

const Container = styled.div`
  width: 100%;
  // max-width: 1068px;
  display: flex;
  justify-content: space-between;
  // gap: ${Spacing(2)};
  margin: 0 auto;
  margin-top: ${Spacing(2)};
  align-self: end;
  align-items: center;

  // @media screen and (max-width: 1400px) {
  //   justify-content: center;
  // }

  // @media screen and (max-width: 1160px) {
  //   padding: 0 10px;
  // }
`;

const FilterButton = styled.button`
  display: none;

  @media screen and (max-width: 1160px) {
    display: block;
    width: 100%;
    padding: ${Spacing(4)};
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  }
`;
