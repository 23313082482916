import { OPTION_INTER } from '../../data/user/constant';

export const convert = (values: any) => ({
  ...values,
  timezone: Number(values.timezone),
  language: values?.language.map((e: OPTION_INTER) => e.value),
  gender: typeof values?.gender === 'number' ? values.gender : null,
  isFop: values?.isFop === 'true' ? true : false,
  bankNumber: String(values?.bankNumber),
});
