import React from 'react';
import ReactDOM from 'react-dom';

import { PSYCHO_LIST_PAGE_PATH } from '../../../page/psycho-list';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_ENUM } from '../../../theme/size';
import { TextElem } from '../../../common/text';
import { useHistory } from 'react-router';

export const HeaderProfile: React.FC<{ price?: string; time?: string }> = ({
  price,
  time,
}) => {
  const history = useHistory();

  const handleAction = (path?: string | boolean) => {
    if (typeof path === 'string') {
      history.push(`${path}`);
    }

    if (path === true) {
      history.go(-1);
    }
  };
  return (
    <>
      {ReactDOM.createPortal(
        <HeaderContainer>
          <TextComponent onClick={() => handleAction(PSYCHO_LIST_PAGE_PATH)}>
            <TextElem
              color="textPlaceholder"
              size="title"
              tid="USER.USER.SELECT_SPECIALIST"
              type="bold"
            />
          </TextComponent>
        </HeaderContainer>,
        document.getElementById('layoutApp') || document.body,
      )}
    </>
  );
};

const TextComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spacing(3)};
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: end;
  margin: 0 auto;
  margin-top: ${Spacing(10)};
  padding: 0px 24px;
  align-items: center;
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(10 * 2)}
  );
`;
