import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Spacing } from '../../../theme';
import { CATEGORY_INTER } from '../constant';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

import { ButtonElem } from '../../../common/button';
import arrowSvg from '../../../asset/svg/button/arrow-down.svg';
import arrowExpandedSvg from '../../../asset/svg/button/arrow-down-expanded.svg';
import { useHistory } from 'react-router';
import { ReadMoreElem } from '../../../common/read-more';
import { DividerElem } from '../../../common/divider';

export const Category: React.FC<{ data?: CATEGORY_INTER }> = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const openFile = (url: string) => {
    window.open(url, '_blank');
  };

  const handleContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isExpanded ? contentRef.current.scrollHeight : 0);
    }
  }, [isExpanded, isReadMore]);

  const href = `/order/${data?.id}`;
  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };

  const isCategoryDot =
    data?.description && data?.description.length > 220 ? '...' : '';

  return (
    <Container onClick={toggleExpand} isExpanded={isExpanded}>
      <Head>
        <TextElem
          type="medium"
          color="textPrimary"
          size="main"
          tid={String(data?.category)}
        />

        <OrderServiceContainer>
          {!isExpanded && (
            <PriceContainer>
              <PriceRow>
                <TextElem
                  oneLine
                  tid="USER.PSYCHO.HEADER.PRICE"
                  tvalue={{ value: data?.price }}
                  color="textLink"
                  type="bold"
                  size="main"
                />
                <TextElem
                  oneLine
                  type="regular"
                  color="textSecondary"
                  tid="USER.PSYCHO.HEADER.TIME"
                  tvalue={{ value: data?.duration }}
                />
              </PriceRow>
              <ButtonElemStyled
                onClick={onClick}
                padding="16px 24px"
                color="dayButton"
                tid="Забронювати"
                size="default"
                type="submit"
              />
            </PriceContainer>
          )}

          <ArrowImg
            isExpanded={isExpanded}
            src={isExpanded ? arrowExpandedSvg : arrowSvg}
            alt="arrow"
          />
        </OrderServiceContainer>
      </Head>

      <ContentWrapper ref={contentRef} height={height}>
        <Content>
          <Info>
            <ReadMoreElem
              onToggle={() => setIsReadMore((prev) => !prev)}
              textLimit={125}
              text={data?.description || ''}
            />

            <DividerElem />

            <FileWrapper>
              <FileContainer>
                <TextContainerStyled>
                  <TextContainer>
                    <TextElem
                      type="medium"
                      color="textPrimary"
                      size="mobileDefault"
                      tid="USER.PSYCHO.PROFILE.TIME_NEED"
                    />

                    <ReadMoreElem
                      textLimit={440}
                      text={`${data?.requireHours} години`}
                    />
                  </TextContainer>

                  <TextContainer>
                    <TextElem
                      type="medium"
                      color="textPrimary"
                      size="mobileDefault"
                      tid="USER.PSYCHO.PROFILE.IS_NIGHT"
                    />

                    <ReadMoreElem
                      textLimit={440}
                      text={
                        `${data?.overNightAvailability}`
                          ? 'USER.PSYCHO.PROFILE.SERVICE.TRUE'
                          : 'USER.PSYCHO.PROFILE.SERVICE.FALSE'
                      }
                    />
                  </TextContainer>

                  <TextContainer>
                    <TextElem
                      type="medium"
                      color="textPrimary"
                      size="mobileDefault"
                      tid="USER.PSYCHO.PROFILE.AROUND_THE_CLOCK"
                    />

                    <ReadMoreElem
                      textLimit={440}
                      text={
                        `${data?.overNightAvailability}`
                          ? 'USER.PSYCHO.PROFILE.SERVICE.TRUE'
                          : 'USER.PSYCHO.PROFILE.SERVICE.FALSE'
                      }
                    />
                  </TextContainer>
                </TextContainerStyled>

                <PriceContainer>
                  <PriceRow>
                    <TextElem
                      oneLine
                      tid="USER.PSYCHO.HEADER.PRICE"
                      tvalue={{ value: data?.price }}
                      color="textLink"
                      type="bold"
                      size="main"
                    />
                    <TextElem
                      oneLine
                      type="regular"
                      color="textSecondary"
                      tid="USER.PSYCHO.HEADER.TIME"
                      tvalue={{ value: data?.duration }}
                    />
                  </PriceRow>
                  {!isExpanded && (
                    <ButtonDisabled
                      type="save"
                      tid="Забронювати"
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClick(e);
                      }}
                    />
                  )}
                  {isExpanded && (
                    <ButtonElemStyled
                      onClick={onClick}
                      padding="16px 24px"
                      color="dayButton"
                      tid="Забронювати"
                      size="default"
                      type="submit"
                    />
                  )}
                </PriceContainer>
              </FileContainer>
            </FileWrapper>
          </Info>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

const OrderServiceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(3)};
`;

const ArrowImg = styled.img<{ isExpanded: boolean }>`
  width: 20px;
  height: 20px;
  margin-left: ${Spacing(2)};
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const ButtonElemStyled = styled(ButtonElem)`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CIRCLE
  ]}px !important;
  padding: 12px 24px !important;
  height: 38px;
  width: 187px;

  span {
    font-size: 13px !important;
    line-height: 1em !important;
  }
`;
const TextContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spacing(3)};
  white-space: nowrap;
`;

const ButtonDisabled = styled(ButtonElem)`
  padding: 16px 24px !important;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CIRCLE
  ]}px !important;
  height: 46px;
  width: 147px;

  span {
    font-size: 13px !important;
    line-height: 1em !important;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  width: 98px;
  height: 70px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const FileList = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  flex-wrap: wrap;
`;

const FileContainer = styled.div`
  display: flex;
  gap: 400px;
  justify-content: space-between;
  align-items: normal;
`;

const FileWrapper = styled.div`
  display: grid;
  grid-gap: ${Spacing(6)};
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  gap: ${Spacing(6)};
`;

const ContentWrapper = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  overflow: hidden;
  transition: all 0.25s ease;
  position: relative;
  margin-top: ${({ height }) => height !== 0 && Spacing(5)};
`;

const Container = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;
