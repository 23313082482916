import { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../text';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';

export const Elem: React.FC<{
  selectedDate: any;
  setSelectedDate: any;
  title?: string;
  placeholder?: string;
  name?: string;
}> = ({ selectedDate, setSelectedDate, title, placeholder }) => {
  //   const [selectedDate, setSelectedDate] = useState('');
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const handleFakeInputClick = () => {
    // Клік на фейковий інпут відкриває вікно вибору дати
    console.log(hiddenInputRef.current);
    hiddenInputRef?.current?.showPicker();
  };

  const handleDateChange = (event: any) => {
    const newDate = event.target.value;
    const date = new Date(newDate);
    console.log(typeof date);
    // const objDate = date.toISOString().split('T')[0];
    setSelectedDate(date); // Оновлюємо дату в стані
  };

  const formatDate = (date: string) => {
    if (!date) return '';
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
    };
    return new Date(date).toLocaleDateString('uk-UA', options); // Формат: DD.MM
  };

  return (
    <>
      <Container>
        {title && (
          <TitleContainer>
            <TextElem
              type="medium"
              color="textSecondary"
              size="input"
              tid={title}
            />
          </TitleContainer>
        )}
        {/* Схований реальний інпут типу date */}
        <input
          ref={hiddenInputRef}
          type="date"
          style={{
            height: '54px',
            visibility: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          value={selectedDate}
          onChange={handleDateChange}
          min={new Date().toISOString().split('T')[0]}
        />

        {/* Фейковий інпут, який відображає вибрану дату */}
        <Wrap>
          <FakeInput
            onClick={handleFakeInputClick}
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              cursor: 'pointer',
              display: 'inline-block',
            }}
          >
            {selectedDate ? (
              <TextElem
                tid={formatDate(selectedDate)}
                color="textDefault"
                size="semiSmall"
                type="medium"
              />
            ) : (
              <TextElem
                tid={placeholder}
                color="textSecondary"
                size="semiSmall"
                type="medium"
              />
            )}
            {/* <TextElem
              tid={formatDate(selectedDate) || placeholder}
              color="textSecondary"
              size="semiSmall"
              type="medium"
            /> */}
            {/* {selectedDate ? selectedDate : 'CARETAKER_LIST.MODAL.DATE'} */}
          </FakeInput>
          {selectedDate && <StyledClose onClick={() => setSelectedDate('')} />}
        </Wrap>
      </Container>
    </>
  );
};

const Wrap = styled.div`
  position: relative;
`;

const StyledClose = styled(CloseIcon)`
  position: absolute;
  top: 19px;
  right: 16px;
  cursor: pointer;
`;

const FakeInput = styled.div`
  width: 100%;
  height: 54px; як селекти, по дизайну 46px на селектах теж
  cursor: pointer;
  padding:  ${Spacing(4)} !important;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border: 1px solid transparent !important;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
  :hover {
    border-color: ${COLOR_DATA[COLOR_ENUM.BORDER_HOVER]} !important;
  }
  user-select: none;
  :focus-within{
  border-color: ${COLOR_DATA[COLOR_ENUM.BORDER_ACTIVE]} !important;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  position: relative;

  input[type='date']::-webkit-calendar-picker {
    width: 400px;
  }
`;
