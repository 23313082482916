import { API, FORM_VALUE_ENUM } from './constant';
import { HttpRequest } from '../../lib/http';

export interface FORM_DATA {
  certificate: File[];
  diploma: File[];
  video?: File;
  category: number;
  id?: string;
  description: string;
}

export const action = ({ payload }: any) => {
  return HttpRequest({
    method: API.MAIN.TYPE,
    url: API.MAIN.URL,
    data: payload,
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
  });
};
