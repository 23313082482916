import React from 'react';
import { FormikValues } from 'formik';
import styled from 'styled-components';

import { FORM_VALUE_ENUM } from './constant';
import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { FieldPasswordElem } from '../../common/field-password';
import { FormElem } from '../../common/form';
import { LoaderElem } from '../../common/loader';
import { LinkElem } from '../../common/link';
import { AUTH_RECOVERY_PAGE_PATH } from '../../page/auth-recovery';
import { AUTH_TYPE } from '../../data/auth/constant';
import { AUTH_SIGNUP_PAGE_PATH } from '../../page/auth-signup';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TabElem } from '../../common/tab';
import { AUTH_LOGIN_PAGE_PATH } from '../../page/auth-login';
import parse from 'html-react-parser';
import { i18n } from '../../lib/lang';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  method: AUTH_TYPE;
  setMethod: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
}) => {
  return (
    <PageContainer>
      <Form onSubmit={formik.handleSubmit}>
        {isLoading && <LoaderElem />}
        <TitleContainer>
          <TextContainer>
            <TextStyled
              size="logo"
              color="textPrimary"
              type="bold"
              tid="AUTH.LOGIN.DESCRIPTION.PREFIX"
              fontFamily="Raleway"
            />

            <TextStyled
              size="logo"
              color="textPrimary"
              type="bold"
              children={parse(
                i18n.t('AUTH.LOGIN.DESCRIPTION.SUFIX', new Object()),
              )}
              fontFamily="Raleway"
              lineHeight="36px"
            />
          </TextContainer>

          <TextStyled
            size="default"
            color="textSecondary"
            type="regular"
            tid="AUTH.LOGIN.DESCRIPTION.POSTFIX"
            fontFamily="Raleway"
          />
        </TitleContainer>

        <GridStyled size="mod">
          <TabElem
            tabList={[
              { tid: 'AUTH.LOGIN.TITLE', path: AUTH_LOGIN_PAGE_PATH },
              { tid: 'AUTH.SIGNUP.TITLE', path: AUTH_SIGNUP_PAGE_PATH },
            ]}
          />

          <GridElem spacing={4}>
            <FieldTextElem
              name={FORM_VALUE_ENUM.EMAIL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="AUTH.LOGIN.EMAIL_PLACEHOLDER"
              title="AUTH.LOGIN.EMAIL"
              value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
              error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
              type="email"
            />

            <FieldPasswordElem
              name={FORM_VALUE_ENUM.PASSWORD}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="AUTH.LOGIN.PASSWORD_PLACEHOLDER"
              title="AUTH.LOGIN.PASSWORD"
              value={getFieldValue(FORM_VALUE_ENUM.PASSWORD)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.PASSWORD)}
              error={isFieldError(FORM_VALUE_ENUM.PASSWORD)}
              type="password"
            />
          </GridElem>

          <GridElem spacing={4}>
            <RecoveryContainer>
              <RecoveryTextStyled
                size="semiSmall"
                type="regular"
                color="textSecondary"
                tid="AUTH.LOGIN.LINKS.RECOVERY.LINK_DESCRIPTION"
                fontFamily="Raleway"
              />
              <LinkElem
                size="semiSmall"
                type="medium"
                justifySelf="center"
                color="textLink"
                tid="AUTH.LOGIN.LINKS.RECOVERY.LINK"
                link={AUTH_RECOVERY_PAGE_PATH}
              />
            </RecoveryContainer>

            <ButtonElem
              disabled={isSubmitDisabled()}
              color="default"
              type="submit"
              fill="solid"
              tid="AUTH.LOGIN.BUTTON"
              sizeText="main"
            />
          </GridElem>

          {isError && <AlertActionElem text={errorMessage} type="error" />}
        </GridStyled>
      </Form>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
`;

const RecoveryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const RecoveryTextStyled = styled(TextElem)`
  line-height: 1.5em;
`;

const TextStyled = styled(TextElem)`
  line-height: 1.7em;
`;

const GridStyled = styled(GridElem)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  padding: ${Spacing(7)};
  gap: ${Spacing(6)};
  max-width: 366px;
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const Form = styled(FormElem)`
  max-width: 628px;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(10)};
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding: 20px;
`;
