import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRef } from 'react';

import {
  CHIP_TEXT_USER_VIEW,
  CHIP_USER_VIEW,
  USER_ITEM_DATA_INTER,
} from '../constant';
import { Spacing } from '../../../theme';
import { CardElem } from '../../../common/card';
import { TextElem } from '../../../common/text';
import { UserItemUpdateContainer } from '../../../epic/user-item-update';
import { ReactComponent as EditIcon } from '../../../asset/svg/common/edit.svg';
import { ContentContainerElem } from '../../../common/content-container';
import { COLOR_ENUM } from '../../../theme/color';

import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import { ChipElem } from '../../../common/chip';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../../theme/value';

interface Props extends USER_ITEM_DATA_INTER {
  updateVisible: string | null;
  setUpdateVisible: Function;
}

const Elem: React.FC<Props> = ({
  id,
  email,
  role,
  password,
  createDate,
  setUpdateVisible,
  name,
  updateVisible,
}) => {
  const handleUpdateVisible = () => {
    setUpdateVisible(id);
  };

  const handleCloseModal = () => {
    setUpdateVisible(null);
  };

  const [height, setHeight] = useState(0);
  const updRef = useRef<any>(null);

  useEffect(() => {
    if (updRef.current) {
      setHeight(updRef.current?.offsetHeight);
    }
  }, [updateVisible]);

  return (
    <Card
      handleClick={handleUpdateVisible}
      height={height}
      updVisible={updateVisible === id}
      // disabled={true}
    >
      {updateVisible === id && (
        <DivStyled ref={updRef}>
          <UpdateWrapper>
            <UserItemUpdateContainer
              data={{ createDate, id, email, name, role, password }}
              close={handleCloseModal}
            />
          </UpdateWrapper>
        </DivStyled>
      )}
      <EllipsisContainerElem style={{ paddingRight: `${Spacing(5)}` }}>
        <TextElem
          className="hovering"
          tid="USER.ITEM.EMAIL"
          tvalue={{ value: email }}
          type="medium"
          size="semiSmall"
          oneLine
        />
      </EllipsisContainerElem>
      <UpdateContainer>
        <Chip
          className="hovering"
          tid={`USER.ROLE.${role}`}
          txColor={CHIP_TEXT_USER_VIEW[role]}
          bgColor={CHIP_USER_VIEW[role]}
        />
        <EditIconStyled className="hovering" />
      </UpdateContainer>
    </Card>
  );
};

const UpdateWrapper = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(5)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD_LIST
  ]}px;
  width: 100%;
`;

const UpdateContainer = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  align-items: center;
  justify-content: space-between;
`;

const Chip = styled(ChipElem)<{ bgColor: string; txColor: string }>`
  background: ${({ bgColor }) => bgColor && bgColor} !important;
  span {
    color: ${({ txColor }) => txColor && txColor} !important;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
  }
`;

const EditIconStyled = styled(EditIcon)`
  transition: all 0.2s;
  fill: ${({ theme }) => theme[COLOR_ENUM.ICON_SECONDARY]};

  height: 18px;
  width: 18px;
  :hover {
    fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  }
`;

const Card = styled(CardElem)<{ height?: number; updVisible: boolean }>`
  display: grid;
  cursor: auto;
  grid-column: span 3;
  grid-template-columns: subgrid;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD_LIST
  ]}px;
  transition: background-color
      ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]},
    opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  ${({ height, updVisible }) => {
    if (updVisible) {
      return css`
        :hover {
          background-color: transparent;
        }
        background-color: transparent;
        height: ${height}px;
      `;
    }
  }}

  &:not(:disabled) {
    &:hover {
      background-color: rgba(255, 255, 255, 0.7);  

      .hovering {
        // opacity: 0.8;
      }
    }
  }
`;

const DivStyled = styled.div`
  width: calc(100% + 2px);
  position: absolute;
  // z-index: 999;
  top: -1px;
  left: -1px;
`;

export { Elem as UserItemElem };
