export const MODULE_NAME = 'FILE_ITEM_UPLOAD_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  FILE = 'file',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.FILE]: any;
}

export enum API_ENUM {
  PHOTO = 'photo',
  ANIMAL_PHOTO = 'animalPhoto',
  ANIMAL_PASSPORT = 'animalPassport',
  DIPLOMA = 'diploma',
  CERTIFICATE = 'certificate',
  VIDEO = 'video',
}

export type API_TYPE = `${API_ENUM}`;

export const FILE_ITEM_TYPE = {
  [API_ENUM.PHOTO]: {
    maxSize: 10 * 1024 * 1024, // 10MB
    acceptedTypes: 'image/jpeg, image/png',
  },
  [API_ENUM.ANIMAL_PHOTO]: {
    maxSize: 10 * 1024 * 1024, // 10MB
    acceptedTypes: 'image/jpeg, image/png',
  },
  [API_ENUM.ANIMAL_PASSPORT]: {
    maxSize: 10 * 1024 * 1024, // 10MB
    acceptedTypes:
      'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png',
  },

  [API_ENUM.DIPLOMA]: {
    maxSize: 5 * 1024 * 1024, // 5MB
    acceptedTypes:
      'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png',
  },
  [API_ENUM.CERTIFICATE]: {
    maxSize: 5 * 1024 * 1024, // 5MB
    acceptedTypes:
      'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png',
  },
  [API_ENUM.VIDEO]: {
    maxSize: 100 * 1024 * 1024, // 100MB
    acceptedTypes: 'video/mp4, video/quicktime',
  },
};

export const API = {
  [API_ENUM.PHOTO]: {
    TYPE: 'POST',
    URL: 'user/photo',
  },
  [API_ENUM.ANIMAL_PHOTO]: {
    TYPE: 'POST',
    URL: 'user/animal-photo',
  },
  [API_ENUM.ANIMAL_PASSPORT]: {
    TYPE: 'POST',
    URL: 'user/animal-passport',
  },

  [API_ENUM.DIPLOMA]: {
    TYPE: 'POST',
    URL: 'user/diploma',
  },
  [API_ENUM.CERTIFICATE]: {
    TYPE: 'POST',
    URL: 'user/certificate',
  },
  [API_ENUM.VIDEO]: {
    TYPE: 'POST',
    URL: 'user/video',
  },
};

export interface ACTION_RESPONSE_INTER {
  accessToken: string;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
