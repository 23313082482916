import styled from 'styled-components';

import { DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';

import { TextElem } from '../../../common/text';
import { ReadMoreElem } from '../../../common/read-more';
import { ButtonElem } from '../../../common/button';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../../theme/value';
import uploadDocumentSvg from '../../../asset/svg/common/document-upload.svg';

const openFile = (url: string) => {
  window.open(url, '_blank');
};

export const Profile: React.FC<{ data?: DATA_INTER }> = ({ data }) => {
  return (
    <MainContainer>
      <NameContainer>
        <NameInfo>
          <ImgContainer>
            <ImgStyled src={data?.photo?.url} alt="photo" />
          </ImgContainer>

          <ColumnContainer>
            <TextElem
              type="bold"
              color="textPrimary"
              size="main"
              tid={data?.name}
            />
            <TextElem
              type="semi-bold"
              color="textSecondary"
              size="mobileDefault"
              tid="USER.PSYCHO.PROFILE.NAME"
              tvalue={{
                city: data?.city && `${data?.city},`,
                country: data?.country && `${data?.country}`,
                age: data?.age && `• ${data?.age}`,
                gender: data?.gender && `• ${data?.gender}`,
                nationality: data?.nationality && `• ${data?.nationality}`,
              }}
            />
            <TextElem />
          </ColumnContainer>
        </NameInfo>

        <ButtonStyled size="default" type="submit">
          <TextElem
            size="default"
            color="textLink"
            tid="USER.USER.ORDER_SERVANT"
          />
        </ButtonStyled>
      </NameContainer>

      <ProfileContainer>
        <InfoWrapper>
          <VideoContainer>
            <TextElem type="medium" color="textPrimary" size="main" />
            <VideoPreviewContainer>
              {data?.videoIntroduction?.url && (
                <VideoStyled controls style={{ width: '504', height: 'auto' }}>
                  <source src={data?.videoIntroduction?.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </VideoStyled>
              )}
              {!data?.videoIntroduction?.url && (
                <TextElem
                  type="medium"
                  color="textPrimary"
                  size="main"
                  tid="USER.PSYCHO.PROFILE.NOT_SET"
                />
              )}
            </VideoPreviewContainer>
          </VideoContainer>

          <InfoContainer>
            <TextElem
              type="medium"
              color="textPrimary"
              size="main"
              tid="USER.PSYCHO.PROFILE.CERTIFICATE"
            />
            <FileContainer>
              {data?.certificate && data?.certificate.length > 0 ? (
                data.certificate.map((e) => (
                  <FilePreview onClick={() => openFile(e.url)}>
                    <img src={uploadDocumentSvg} alt="certificate" />
                  </FilePreview>
                ))
              ) : (
                <TextElem
                  type="medium"
                  color="textPrimary"
                  size="main"
                  tid="USER.PSYCHO.PROFILE.NOT_SET"
                />
              )}
            </FileContainer>
          </InfoContainer>
        </InfoWrapper>

        <InfoContainer>
          <TextContainer>
            <TextElem
              type="medium"
              color="textPrimary"
              size="main"
              tid="USER.PSYCHO.PROFILE.ABOUT_MY_ME"
            />

            <ReadMoreElem
              textLimit={440}
              text={data?.aboutPerson || 'USER.PSYCHO.PROFILE.NOT_SET'}
            />
          </TextContainer>

          <TextContainer>
            <TextElem
              type="medium"
              color="textPrimary"
              size="main"
              tid="USER.PSYCHO.PROFILE.MY_HISTORY"
            />

            <ReadMoreElem
              textLimit={440}
              text={data?.myPath || 'USER.PSYCHO.PROFILE.NOT_SET'}
            />
          </TextContainer>

          <TextContainer>
            <TextElem
              type="medium"
              color="textPrimary"
              size="main"
              tid="USER.PSYCHO.PROFILE.INFO_ABOUT_ME_LIKE_SPECIALIST"
            />

            <ReadMoreElem
              textLimit={440}
              text={data?.aboutSpecialist || 'USER.PSYCHO.PROFILE.NOT_SET'}
            />
          </TextContainer>
        </InfoContainer>
      </ProfileContainer>
    </MainContainer>
  );
};

const FileContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  max-width: ${Spacing(105)};
  flex-wrap: wrap;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  width: 98px;
  height: 70px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const ButtonStyled = styled(ButtonElem)`
  width: 50%;
  display: flex;
  padding: 16px 32px;
  max-width: 206px;
  max-height: 88px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing(3)};
  height: 100%;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 6px;
}
`;

const VideoContainer = styled.div`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;

  padding: 22px ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
`;

const VideoPreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NameInfo = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  height: 100%;
  justify-content: end;
`;

const ImgContainer = styled.div`
  min-width: 72px;
  min-height: 72px;
`;

const ImgStyled = styled.img`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  width: 72px;
  height: 72px;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(3)};
  padding: 22px ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const NameContainer = styled.div`
  max-width: 100%;
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  display: flex;
  justify-content: space-between;
  gap: ${Spacing(4)};
  padding: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  align-items: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const ProfileContainer = styled.div`
  display: grid;
  gap: ${Spacing(4)};
  grid-template-columns: 1fr 1fr;
`;
