import React from 'react';
import { useLocation } from 'react-router';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from '../../lib/store';
import { Component } from './component';
import { AUTH_MODULE_NAME } from '../../data/auth';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

export const Container: React.FC = () => {
  const router = useLocation();

  const isActivePath = (...path: string[]) =>
    path.some((el) => router.pathname.includes(el));

  const { theme, auth } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
    auth: s[AUTH_MODULE_NAME],
  }));

  const isLogged = () => auth.logged;

  return (
    <React.Fragment>
      {isLogged() ? (
        ReactDOM.createPortal(
          <Component
            theme={theme.type}
            isActivePath={isActivePath}
            pathname={router.pathname}
          />,
          document.getElementById('layoutApp1') || document.body,
        )
      ) : ( 
        <>
          {ReactDOM.createPortal(
            <ContainerUnlogged />,
            document.getElementById('layoutApp1') || document.body,
          )}
        </>
      )}
    </React.Fragment>
  );
};

const ContainerUnlogged = styled.div`
  grid-row: 1/3;
  margin: 12px;
`;
