import Placeholder from 'react-select/dist/declarations/src/components/Placeholder';
import { CATEGORY_VIEW, GENDER_VIEW } from '../../epic/profile-psycho/constant';
import { i18n } from '../../lib/lang/core';
import {
  USER_ITEM_DATA_RAW_INTER,
  USER_ITEM_DATA_INTER,
  USER_ITEM_LIST_DATA_RAW_INTER,
  USER_ITEM_LIST_DATA_INTER,
  methodViewObj,
  requestViewObj,
  PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
  PSYCHOLOGIST_ITEM_DATA_INTER,
  PLACEMENT_VIEW,
  ANIMAL_SIZE_VIEW,
  ANIMAL_TYPE_VIEW,
  SPECIALIST_VIEW,
  LANGUAGE_VIEW,
  VACCINATION_VIEW,
  TRANSPORTATION_VIEW,
} from './constant';

const convertArray = (
  array: string[] | null | any,
  viewObj: Record<string, string>,
) =>
  array
    ? array.map((e: string) => ({
        label: viewObj[e],
        value: e,
      }))
    : [];

export const convertUser = (
  user: USER_ITEM_DATA_RAW_INTER,
): USER_ITEM_DATA_INTER => {
  return {
    ...user,
    methodList: convertArray(user?.methodList, methodViewObj),
    requestList: convertArray(user?.requestList, requestViewObj),
    lgbtFrendly: String(user?.lgbtFrendly),
    psychoCategoryView: {
      label: user?.psychoCategory ? CATEGORY_VIEW[user?.psychoCategory] : '',
      value: user?.psychoCategory,
    },
    gender: {
      label: user?.gender ? GENDER_VIEW[user.gender] : '',
      value: user?.gender,
    },
    language: convertArray(user?.language, LANGUAGE_VIEW),
    animalType: {
      label: user?.animalType ? ANIMAL_TYPE_VIEW[user.animalType] : '',
      value: user?.animalType,
    },
    animalSize: {
      label: user?.animalSize ? ANIMAL_SIZE_VIEW[user.animalSize] : '',
      value: user?.animalSize,
    },

    needGender: {
      label: user?.needGender ? GENDER_VIEW[user.needGender] : '',
      value: user?.needGender,
    },
    needLanguage: convertArray(user?.needLanguage, LANGUAGE_VIEW),
    needPreviousExperience: user?.needPreviousExperience ? 'true' : 'false',
    needPlacement: {
      label: user?.needPlacement ? PLACEMENT_VIEW[user.needPlacement] : '',
      value: user?.needPlacement,
    },
    specialistNeeded: convertArray(user?.specialistNeeded, SPECIALIST_VIEW),
    needCompatibility: String(user?.needCompatibility),
    compatibilityAnimal: String(user?.compatibilityAnimal),
    vaccination: convertArray(user?.vaccination, VACCINATION_VIEW),
  };
};

export const convertPsychologist = (
  user: PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
): PSYCHOLOGIST_ITEM_DATA_INTER => {
  return {
    ...user,
    gender: {
      label: user?.gender ? GENDER_VIEW[user.gender] : '',
      value: user?.gender,
    },
    language: convertArray(user?.language, LANGUAGE_VIEW),
    isFop: String(user?.isFop),
    workAnimalType: convertArray(user?.workAnimalType, ANIMAL_TYPE_VIEW),
    specialistWith: convertArray(user?.specialistWith, SPECIALIST_VIEW),
    transportation: convertArray(user?.transportation, TRANSPORTATION_VIEW),
    placement: convertArray(user?.placement, PLACEMENT_VIEW),
    ableCompatibilityAnimal: String(user?.ableCompatibilityAnimal),
    medicationExperience: String(user?.medicationExperience),

    // methodList: convertArray(user?.methodList, methodViewObj),
    // requestList: convertArray(user?.requestList, requestViewObj),
    // gender: user.gender ? user.gender : null,
    // needGender: user.needGender ? user.needGender : null,
    // lgbtFrendly: String(user.lgbtFrendly),
  };
};

export const converPsychoFilter = (user: any) => {
  return {
    workAnimalType:
      typeof user?.animalType != 'string'
        ? convertArray(user?.animalType, ANIMAL_TYPE_VIEW)
        : [
            {
              label: ANIMAL_TYPE_VIEW[user.animalType],
              value: user.animalType,
            },
          ],
    // workAnimalType: user?.animalType || [],
    // workAnimalType: user?.animalType
    //   ? [
    //       {
    //         label: ANIMAL_TYPE_VIEW[user.animalType],
    //         value: user?.animalType,
    //       },
    //     ]
    //   : [],
    // psychoCategory: user.psychoCategory,
    city: user?.city || '',
    specialistWith: convertArray(user?.specialistNeeded, SPECIALIST_VIEW),
    // placement: user?.needPlacement
    //   ? {
    //       label: PLACEMENT_VIEW[user.needPlacement],
    //       value: user.needPlacement,
    //     }
    //   : '',
    placement: user?.needPlacement || '',

    gender: user?.needGender || null,
    age: user?.needAge || null,
    ableCompatibilityAnimal:
      user?.needCompatibility != null ? String(user.needCompatibility) : null,
    medicationExperience:
      user?.medicationExperience != null
        ? String(user.medicationExperience)
        : null,
    // methodList: convertArray(user.methodList, methodViewObj),
    // requestList: convertArray(user.requestList, requestViewObj),
    // psychoCategory: user.psychoCategory,
  };
};

export const convertUserList = (
  userList: USER_ITEM_LIST_DATA_RAW_INTER,
): USER_ITEM_LIST_DATA_INTER => {
  return {
    list: userList.list?.map((user: USER_ITEM_DATA_RAW_INTER) => {
      return convertUser(user);
    }),
    isEmpty: !userList.list?.length,
  };
};
