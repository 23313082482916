import { AuthPhotoLayoutProps } from './constant';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { AuthPhoto } from '../auth-photo';

export const Elem: React.FC<AuthPhotoLayoutProps> = ({ children }) => {
  return (
    <GridContainer>
      <LeftColumn>
        <AuthPhotoStyled />
      </LeftColumn>
      <RightColumn>{children}</RightColumn>
    </GridContainer>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100vh;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

const LeftColumn = styled.div`
  background-color: ${COLOR_ENUM.BACKGROUND_PRIMARY};
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const AuthPhotoStyled = styled(AuthPhoto)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const RightColumn = styled.div`
  padding: ${Spacing(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./assets/svg/header/auth-rectangle.svg');
  background-position: top right;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    background-image: none;
    padding: ${Spacing(3)};
  }
`;
