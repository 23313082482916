import React from 'react';

import styled from 'styled-components';
import { FormikValues } from 'formik';
import { FieldPasswordElem } from '../../common/field-password';
import { FieldTextElem } from '../../common/field-text';
import { SelectElem } from '../../common/select';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { DATA_INTER } from '../psycho-list/constant';
import { FORM_VALUE_ENUM } from './constant';
import { FormElem } from '../../common/form';
import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { TIMEZONE_OPTION_LIST } from '../../data/user/timezone';

export const Component: React.FC<{
  data?: DATA_INTER;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  isFieldError: Function;
  getFieldError: Function;
  formik: FormikValues;
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({
  data,
  setFieldValue,
  getFieldError,
  isFieldError,
  setValue,
  getFieldValue,
  formik,
  isSubmitDisabled,
  isLoading,
  isError,
  errorMessage,
}) => {
  return (
    <InfoContainer>
      <TextContainer>
        <TextElem
          type="medium"
          color="textPrimary"
          size="main"
          tid="ORDER.ITEM.REGISTRATION.TITLE"
        />
        <TextElem
          type="regular"
          color="textSecondary"
          size="input"
          tid="ORDER.ITEM.REGISTRATION.DESCRIPTION"
        />
      </TextContainer>
      <SelectContainer>
        <Column>
          <FieldTextElem
            name={FORM_VALUE_ENUM.NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="ORDER.ITEM.REGISTRATION.NAME"
            placeholder="ORDER.ITEM.REGISTRATION.NAME_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.NAME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
            error={isFieldError(FORM_VALUE_ENUM.NAME)}
          />
          <FieldTextElem
            name={FORM_VALUE_ENUM.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="ORDER.ITEM.REGISTRATION.EMAIL"
            placeholder="ORDER.ITEM.REGISTRATION.EMAIL_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
            error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
            type="email"
          />
        </Column>
        <Column>
          {/* <SelectElem
            placeholder="ORDER.ITEM.REGISTRATION.TIMEZONE_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TIMEZONE}
            onChange={setFieldValue}
            options={TIMEZONE_OPTION_LIST}
            title="ORDER.ITEM.REGISTRATION.TIMEZONE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIMEZONE)}
            error={isFieldError(FORM_VALUE_ENUM.TIMEZONE)}
            value={setValue(TIMEZONE_OPTION_LIST, FORM_VALUE_ENUM.TIMEZONE)}
          /> */}

          <SelectElem
            placeholder="ORDER.ITEM.REGISTRATION.TIMEZONE_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TIMEZONE}
            onChange={setFieldValue}
            options={TIMEZONE_OPTION_LIST}
            dynamic
            title="ORDER.ITEM.REGISTRATION.TIMEZONE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIMEZONE)}
            error={isFieldError(FORM_VALUE_ENUM.TIMEZONE)}
            value={setValue(TIMEZONE_OPTION_LIST, FORM_VALUE_ENUM.TIMEZONE)}
          />
          <FieldPasswordElem
            name={FORM_VALUE_ENUM.PASSWORD}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="ORDER.ITEM.REGISTRATION.PASSWORD"
            value={getFieldValue(FORM_VALUE_ENUM.PASSWORD)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PASSWORD)}
            error={isFieldError(FORM_VALUE_ENUM.PASSWORD)}
          />
        </Column>

        <ButtonElem
          disabled={isSubmitDisabled}
          type="submit"
          onClick={formik.handleSubmit}
          tid="ORDER.ITEM.REGISTRATION.CONFIRM"
        />
      </SelectContainer>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
    </InfoContainer>
  );
};

const Column = styled.div`
  display: flex;
  gap: ${Spacing(3)};
`;

const SelectContainer = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  flex-direction: column;
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  flex-direction: column;
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(6)};
  padding: ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;
