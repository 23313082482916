export const multiValueContainer = ({ selectProps, data }: any) => {
  const label = data.label;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex(
    (selected: any) => selected.label === label,
  );
  const isLastSelected = index === allSelected?.length - 1;
  const labelSuffix = isLastSelected ? '' : ', ';

  return (
    <span translate="no">
      {label}
      <span>{labelSuffix}</span>
    </span>
  );
};
