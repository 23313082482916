import { HttpRequest } from '../../lib/http';
import { FILTER_OPTION } from '../user-list-filter/constant';
import { API } from './constant';

export const action = (filterOption: FILTER_OPTION) => {
  let param = '?';

  if (filterOption?.indentify) {
    param += `indentify=${filterOption.indentify}&`;
  }
  if (filterOption?.role) {
    param += `role=${filterOption.role}&`;
  }
  param = param.slice(0, -1);
  return HttpRequest({
    method: API.TYPE,
    url: API.URL(param),
  });
};
