import { API, FORM_VALUE_INTER, USER_DATA_RAW } from './constant';
import { HttpRequest } from '../../lib/http';

export const action = (payload: USER_DATA_RAW) => async () => {
  return HttpRequest({
    method: API.UPDATE.TYPE,
    url: API.UPDATE.URL,
    data: payload,
  });
};

export const postPhotoAction = async (photo?: File | null) => {
  const formData = new FormData();

  if (photo && photo instanceof File) {
    formData.append('photo', photo);
  }

  return HttpRequest({
    method: API.POST.TYPE,
    url: API.POST.URL,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const removeAction = (id: string | null) => {
  return HttpRequest({
    method: API.DELETE.TYPE,
    url: API.DELETE.URL(id),
  });
};

export const getAction = () => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL,
  });
};
