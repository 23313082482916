import { API, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';
import { Dispatch } from '../../lib/store';

export const action =
  (payload: FORM_VALUE_INTER) => async (dispatch: Dispatch) => {
    return HttpRequest({
      method: API.MAIN.TYPE,
      url: API.MAIN.URL,
      data: payload,
    });
  };

export const getAction = () => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL,
  });
};

export const getActionPhoto = () => {
  return HttpRequest({
    method: API.PHOTO.GET.TYPE,
    url: API.PHOTO.GET.URL,
  });
};

export const removeAction = (id: string | null) => {
  return HttpRequest({
    method: API.PHOTO.DELETE.TYPE,
    url: API.PHOTO.DELETE.URL(id),
  });
};
