import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({ pageParam }: any) => {
  let params = '';
  if (pageParam) params += `&skip=${pageParam}`;

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
