import { USER_ROLE } from '../../data/auth/constant';
import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = async (pageParam: any) => {
  let params = '';
  if (pageParam) params += `&skip=${pageParam}`;
  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
