import React from 'react';

import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import styled from 'styled-components';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { FormElem } from '../../common/form';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { ContentContainerElem } from '../../common/content-container';

export const Component: React.FC<{
  onSubmitForm: any;
}> = ({ onSubmitForm }) => {
  return (
    <ContentContainerElem>
      <FormElem onSubmit={onSubmitForm}>
        <GridStyled spacing={2}>
          <TextContainer>
            <TextElem
              size="label"
              color="textPrimary"
              type="semi-bold"
              tid="SETTINGS.LOGOUT.TITLE"
            />
            <TextElem
              size="semiSmall"
              color="textThird"
              type="regular"
              tid="SETTINGS.LOGOUT.DESCRIPTION"
            />
          </TextContainer>

          <GridStyled spacing={4}>
            <ButtonStyled type="submit" tid="SETTINGS.LOGOUT.BUTTON" />
          </GridStyled>
        </GridStyled>
      </FormElem>
    </ContentContainerElem>
  );
};

const GridStyled = styled(GridElem)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const ButtonStyled = styled(ButtonElem)`
 width: 70%;
  padding: ${Spacing(5)};
  border-radius: 10px;
  background-color ${COLOR_DATA[COLOR_ENUM.BUTTON_SETTING_LOG_OUT]};

   span {
     color: ${COLOR_DATA[COLOR_ENUM.ERROR]} !important;
   }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  gap: ${Spacing(3)};
`;
