import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'WELLCOME_LOGOUT_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  NAME_PET = 'animalType',
  CITY = 'city',
  TIME = 'time',
  SERVICE = 'service',
  REGION = 'region',
  DATE = 'date',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.NAME_PET]: string;
  [FORM_VALUE_ENUM.CITY]: string;
  [FORM_VALUE_ENUM.TIME]: string;
  [FORM_VALUE_ENUM.SERVICE]: string;
  [FORM_VALUE_ENUM.REGION]: string;
  [FORM_VALUE_ENUM.DATE]: string | null;
}

export const API = {
  MAIN: {
    TYPE: 'POST',
    URL: '/auth/signup',
  },
};

export interface ACTION_RESPONSE_INTER {
  accessToken: string;
}

export const CATALOG_ITEM_LIST = [
  {
    id: 3,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.SUBTITLE')}`,
  },
  {
    id: 1,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.SUBTITLE')}`,
  },
  {
    id: 5,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.SUBTITLE')}`,
  },
  {
    id: 2,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.SUBTITLE')}`,
  },
  {
    id: 4,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.SUBTITLE')}`,
  },
  {
    id: 6,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.SUBTITLE')}`,
  },
];

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export interface Option {
  value: string;
  label: string;
}

export const petsOptions: Option[] = [
  { value: 'dog', label: 'Собака' },
  { value: 'cat', label: 'Кіт' },
  { value: 'gine pig', label: 'Морська свинка' },
];

export const servicesOptions = [
  { value: 'grooming', label: 'Стрижка' },
  { value: 'walking', label: 'Прогулянка' },
  { value: 'training', label: 'Тренування і опіка' },
];

export const citiesOptions = [
  { value: 'city1', label: 'Харків' },
  { value: 'city2', label: 'Київ' },
  { value: 'city3', label: 'Дніпро' },
];

export const regionsOptions = [
  { value: 'region1', label: 'Харківський' },
  { value: 'region2', label: 'Київський' },
  { value: 'region3', label: 'Дніпропетровський' },
];

export const timeOptions = [
  { value: 'morning', label: '8:00' },
  { value: 'afternoon', label: '12:00' },
  { value: 'evening', label: '18:00' },
];

export const dateOptions = [
  { value: 'date1', label: '25.02.2010' },
  { value: 'date2', label: '19.07.2006' },
  { value: 'date3', label: '04.10.2021' },
];
