import React from 'react';
import { Tooltip } from 'antd';
import { i18n } from '../../lib/lang';
import { COLOR_DATA } from '../../theme/color';

export const Elem: React.FC<{ tid: string; color?: string }> = ({
  tid,
  children,
  color,
}) => {
  const text = i18n.t(tid);
  return (
    <Tooltip overlayStyle={{color: 'red'}} trigger="hover" color={color || 'white'} title={text}>
      {children}
    </Tooltip>
  );
};
