import { RouteComponentProps } from 'react-router';
import { LayoutElem } from '../../common/layout';
import { AuthLoginContainer } from '../../epic/auth-login';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { AuthHeader } from '../../common/auth-header';
import { GridElem } from '../../common/grid';
import { AuthPhotoLayout } from '../../common/auth_photo-layout';
import styled from 'styled-components';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <AuthPhotoLayout>
          <GridElem spacing={6}>
            <AuthHeader />
            <LayoutElem size="small" direction="column" oneColumn>
              <AuthLoginContainer />
            </LayoutElem>
          </GridElem>
        </AuthPhotoLayout>
      </LayoutAppElem>
    </PageElem>
  );
};
