import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_FILE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export type FILE_TYPE =
  | File
  | null
  | {
      url: string;
      id: string;
    };

export const API = {
  GET: {
    TYPE: 'GET',
    URL: 'user/psychologist-file',
  },
  POST_PHOTO: {
    TYPE: 'POST',
    URL: 'user/photo',
  },
  POST_DIPLOMA: {
    TYPE: 'POST',
    URL: 'user/diploma',
  },
  POST_VIDEO: {
    TYPE: 'POST',
    URL: 'user/video',
  },
  POST_CERTIFICATE: {
    TYPE: 'POST',
    URL: 'user/certificate',
  },

  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string | null) => `file/${id}`,
  },
};

export enum FORM_VALUE_ENUM {
  NAME = 'name',
  PHONE = 'phone',
  REQUEST_LIST = 'requestList',
  REQUEST_CUSTOM = 'requestCustom',
  LGBT_FRENDLY = 'lgbtFrendly',
  AGE = 'age',
  GENDER = 'gender',
  PHOTO = 'photo',
  METHOD_LIST = 'methodList',
  METHOD_CUSTOM = 'methodCustom',
  ABOUT_ME = 'aboutMe',
  TIMEZONE = 'timezone',
  ABOUT_MY_PATH = 'aboutMyPath',
  ABOUT_MY_SERVICE = 'aboutMyService',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.REQUEST_LIST]: string;
  [FORM_VALUE_ENUM.REQUEST_CUSTOM]: string;
  [FORM_VALUE_ENUM.LGBT_FRENDLY]: string;
  [FORM_VALUE_ENUM.AGE]: string;
  [FORM_VALUE_ENUM.GENDER]: string;
  [FORM_VALUE_ENUM.PHOTO]: File;
  [FORM_VALUE_ENUM.METHOD_LIST]: string;
  [FORM_VALUE_ENUM.METHOD_CUSTOM]: string;
  [FORM_VALUE_ENUM.ABOUT_ME]: string;
  [FORM_VALUE_ENUM.ABOUT_MY_SERVICE]: string;
  [FORM_VALUE_ENUM.TIMEZONE]: string;
}

export const GENDER_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 'MALE',
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 'FEMALE',
  },
];

export const NEED_AGE_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 'MALE',
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 'FEMALE',
  },
];

export const LGBT_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.YES'),
    value: 'YES',
  },
  {
    label: i18n.t('USER.USER.SELECT.NO'),
    value: 'NO',
  },
];

export const METHOD_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 'MALE',
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 'FEMALE',
  },
];

export const REQUEST_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 'MALE',
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 'FEMALE',
  },
];

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
