import React, { useState } from 'react';
import styled from 'styled-components';
import { TextElem } from '../text';
import { COLOR_ENUM } from '../../theme/color';
import { i18n } from '../../lib/lang';

export const Elem: React.FC<{
  textLimit?: number;
  text: string | string[];
  onToggle?: Function;
}> = ({ textLimit = 3, text, onToggle }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsReadMore(!isReadMore);
    onToggle && onToggle();
  };

  const renderBadges = () => {
    if (Array.isArray(text)) {
      const displayedItems = isReadMore ? text.slice(0, textLimit) : text;
      return (
        <BadgeContainer>
          {displayedItems.map((item, index) => (
            <Badge key={index}>{i18n.t(item)}</Badge>
          ))}
          {text.length > textLimit && (
            <SpanStyled onClick={toggleReadMore}>
              {isReadMore ? 'Читати більше' : 'Згорнути'}
            </SpanStyled>
          )}
        </BadgeContainer>
      );
    }

    const localText = i18n.t(text);
    const isReadMoreText = localText.length > textLimit;

    return (
      <>
        {isReadMore && isReadMoreText
          ? localText.slice(0, textLimit) + '...'
          : localText}
        {isReadMoreText && (
          <SpanStyled onClick={toggleReadMore}>
            {isReadMore ? 'Читати більше' : 'Згорнути'}
          </SpanStyled>
        )}
      </>
    );
  };

  return (
    <TextElem
      lineHeight="1.6em"
      as="p"
      type="medium"
      color="textSecondary"
      size="input"
    >
      {renderBadges()}
    </TextElem>
  );
};

const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const Badge = styled.span`
  padding: 6px 12px;
  border: 1px solid #f5f6fa;
  border-radius: 16px;
  background-color: #f5f6fa;
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  font-size: 14px;
  line-height: 1.4;
`;

const SpanStyled = styled.span`
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  cursor: pointer;
  margin-left: 4px;
  font-weight: bold;
`;
