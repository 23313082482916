import { USER_ROLE } from '../../data/auth/constant';
import { i18n } from '../../lib/lang';
import { email } from './../../lib/validation/service';

export const MODULE_NAME = 'CONSULTATION_lLIST_FILTER_MODULE_NAME';

export const USER_OPTION_LIST_QUERY_KEY = 'USER_OPTION_LIST_QUERY_KEY';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (param: string) => `/user/admin/option/${param}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface ACTION_RESPONSE_INTER {
  list: [name: string, id: string, phone: string, email: string];
}

export enum FORM_VALUE_ENUM {
  USERS = 'users',
  ROLE = 'role',
}

export interface FILTER_OPTION {
  indentify?: string;
  role?: USER_ROLE;
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.ROLE]: string;
  [FORM_VALUE_ENUM.USERS]: string;
}
