import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { AUTH_TYPE } from '../../data/auth/constant';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  method: AUTH_TYPE;
  setMethod: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
}) => {
  return (
    <Form onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <GridStyled size="mod">
        <TextContainer>
          <TextElemStyled
            size="logo"
            color="textPrimary"
            type="bold"
            tid="AUTH.RECOVERY.TITLE"
            fontFamily="Raleway"
          />

          <TextElemStyled
            size="default"
            color="textSecondary"
            type="regular"
            tid="AUTH.RECOVERY.DESCRIPTION"
            fontFamily="Raleway"
          />
        </TextContainer>

        <GridElem size="input">
          <FieldTextElem
            name={FORM_VALUE_ENUM.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="AUTH.RECOVERY.EMAIL"
            value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
            error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
            type="email"
          />
        </GridElem>

        <ButtonElem
          onClick={formik.handleSubmit}
          disabled={isSubmitDisabled()}
          color="default"
          type="submit"
          fill="solid"
          tid="AUTH.RECOVERY.BUTTON"
          sizeText="main"
        />
        {isError && <AlertActionElem text={errorMessage} />}
      </GridStyled>
    </Form>
  );
};

const TextElemStyled = styled(TextElem)`
  text-align: center;
  line-height: 1.7em;
`;

const GridStyled = styled(GridElem)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  padding: ${Spacing(7)};
  gap: 19px;
  max-width: 366px;
  justify-items: center;
`;

const Form = styled(FormElem)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(10)};
  max-width: 628px;
  align-items: center;
`;

const TextContainer = styled.div`
  display: block;
  gap: 2px;
  text-align: center;
`;
