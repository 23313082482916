import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDispatch } from '../../lib/store';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME_PHOTO,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import {
  action as fetch,
  getAction,
  getActionPhoto,
  removeAction,
} from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  phoneOperator,
  phoneUA,
  required,
  requiredArray,
} from '../../lib/validation/service';
import { convertPsychologist } from '../../data/user/convert';
import {
  PSYCHOLOGIST_ITEM_DATA_INTER,
  PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
  USER_FILE_ITEM_DATA_RAW,
} from '../../data/user/constant';
import { convert } from './convert';

const config = {
  [FORM_VALUE_ENUM.NAME]: [required],
  [FORM_VALUE_ENUM.AGE]: [required],
  [FORM_VALUE_ENUM.GENDER]: [],
  [FORM_VALUE_ENUM.TIMEZONE]: [required],
  [FORM_VALUE_ENUM.PHONE]: [phoneOperator, phoneUA, required],
  [FORM_VALUE_ENUM.CITY]: [required],
  [FORM_VALUE_ENUM.COUNTRY]: [required],
  [FORM_VALUE_ENUM.LANGUAGE]: [required, requiredArray],
  [FORM_VALUE_ENUM.ISFOP]: [],
  [FORM_VALUE_ENUM.BANK_NUMBER]: [required],
  [FORM_VALUE_ENUM.BANK_NAME]: [],
  [FORM_VALUE_ENUM.NATIONALITY]: [],
};

const validate = (values: FormikValues) => validation(values, config);

export const Container: React.FC = () => {
  // const [photoData, setPhotoData] = useState<
  //   USER_FILE_ITEM_DATA_RAW | undefined
  // >(undefined);

  const query = useQueryClient();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<any>({});

  const action = useMutation((d: FORM_VALUE_INTER) => dispatch(fetch(d)), {
    onSuccess: () => {
      return query.invalidateQueries(MODULE_NAME);
    },
  });

  // const preFetchPhoto = useQuery<any>(MODULE_NAME_PHOTO, () =>
  //   getActionPhoto(),
  // );

  const preFetch = useQuery(MODULE_NAME, () => getAction());

  const getData = async (): Promise<
    PSYCHOLOGIST_ITEM_DATA_INTER | undefined
  > => {
    const data =
      (await preFetch.data) as unknown as PSYCHOLOGIST_ITEM_DATA_RAW_INTER;

    if (data) {
      return convertPsychologist(data);
    }
  };

  // const onRefetch = () => {
  //   preFetchPhoto.refetch();
  // };

  const fetchData = async () => {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const offsetInHours = -offsetInMinutes / 60;

    if (preFetch.data) {
      const data = await getData();

      if (preFetch.isSuccess && data) {
        setInitialValues({
          [FORM_VALUE_ENUM.PHONE]: data?.phone || '',
          [FORM_VALUE_ENUM.NAME]: data?.name || '',
          [FORM_VALUE_ENUM.AGE]: data?.age || null,
          [FORM_VALUE_ENUM.GENDER]: data?.gender || null,
          [FORM_VALUE_ENUM.TIMEZONE]: data?.timezone || offsetInHours ,
          [FORM_VALUE_ENUM.CITY]: data?.city || '',
          [FORM_VALUE_ENUM.COUNTRY]: data?.country || '',
          [FORM_VALUE_ENUM.LANGUAGE]: data?.language || [],

          [FORM_VALUE_ENUM.ISFOP]: data?.isFop || '',
          [FORM_VALUE_ENUM.BANK_NAME]: data?.bankName || '',
          [FORM_VALUE_ENUM.BANK_NUMBER]: data?.bankNumber || null,
          [FORM_VALUE_ENUM.NATIONALITY]: data?.nationality || '',
        });
      }
    }
  };

  const deleteAction = useMutation(
    (id: string | null) => {
      return removeAction(id);
    },
    {
      onSuccess: () => {
        query.invalidateQueries(MODULE_NAME_PHOTO);
      },
    },
  );

  // const onDelete = async () => {
  //   if (preFetchPhoto?.data?.photo?.id) {
  //     const id = preFetchPhoto.data.photo.id;
  //     return deleteAction.mutate(id);
  //   }
  // };

  useEffect(() => {
    fetchData();
    if (action.isSuccess) {
      formik.resetForm();
    }
  }, [preFetch.isSuccess, action.isSuccess]);

  const formik: FormikValues = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      const convertedValues = convert(values);
      return action.mutate(convertedValues);
    },
  });

  // useEffect(() => {
  //   formik.setValues(initialValues); // почему-то по другому формик не инициализирует значения, даже если data есть // переделать с использованием enableReinitialize
  // }, [initialValues]);

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading || preFetch.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const preFetchError: ACTION_ERROR_INTER =
      preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
    if (preFetchError) {
      return preFetchError.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => {
    return formik.values[name];
  };

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, e.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    if (typeof formik.values[value] != 'object') {
      const optionList = options?.filter(
        (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
      )[0];
      return optionList ? optionList : null;
    }
    const optionList = options?.filter((item: SELECT_OPTION_ITEM_DATA) => {
      return item.value == formik.values[value]?.value;
    })[0];
    return optionList ? optionList : null;
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  return (
    <Component
      // deleteAction={onDelete}
      // onRefetch={onRefetch}
      // photoUrl={preFetchPhoto?.data?.photo?.url}
      onChangeSelect={onChangeSelect}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoadingAction={action.isLoading}
      isSuccessAction={action.isSuccess}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
    />
  );
};
