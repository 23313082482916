import { USER_ROLE } from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (id?: string) => `consultation/item/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export const USER_ROLE_VIEW = {
  [USER_ROLE.CARETAKER]: i18n.t('CONSULTATION.ROLE.CARETAKER'),
  [USER_ROLE.USER]: i18n.t('CONSULTATION.ROLE.USER'),
  [USER_ROLE.ADMIN]: i18n.t('CONSULTATION.ROLE.ADMIN')
};

export const tabListUser = [
  { tid: 'CONSULTATION.USER.TAB.CONSULTATION', value: 1 },
  { tid: 'CONSULTATION.USER.TAB.CLIENT', value: 2 },
];

export const tabListPsycho = [
  { tid: 'CONSULTATION.PSYCHOLOGIST.TAB.CONSULTATION', value: 1 },
  { tid: 'CONSULTATION.PSYCHOLOGIST.TAB.CLIENT', value: 2 },
];
