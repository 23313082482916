import { OPTION_INTER } from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PSYCHO_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: `/user/request`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export enum FORM_VALUE_ENUM {
  PSYCHO_CATEGORY = 'psychoCategory',
  CARETAKER_CATEGORY = 'caretakerCategory',
  PRICE = 'price',
  TIME = 'time',
  DATE = 'date',

  ANIMAL_TYPE_LIST = 'workAnimalType',
  SPECIALIST_LIST = 'specialistWith',
  PLACEMENT_LIST = 'placement',
  COMPATIBILITY_ANIMAL = 'ableCompatibilityAnimal',
  MEDICATION_EXPERIENCE = 'medicationExperience',

  AGE = 'age',
  GENDER = 'gender',
  CITY = 'city',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.CARETAKER_CATEGORY]: string;
  [FORM_VALUE_ENUM.PRICE]: number;
  [FORM_VALUE_ENUM.TIME]: string[];

  [FORM_VALUE_ENUM.ANIMAL_TYPE_LIST]: OPTION_INTER[] | [];
  [FORM_VALUE_ENUM.SPECIALIST_LIST]: OPTION_INTER[] | [];
  [FORM_VALUE_ENUM.PLACEMENT_LIST]: OPTION_INTER | string;
  [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]: string;
  [FORM_VALUE_ENUM.MEDICATION_EXPERIENCE]: string;

  [FORM_VALUE_ENUM.GENDER]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.AGE]: number;
  [FORM_VALUE_ENUM.CITY]: OPTION_INTER;
}

export const CITY_OPTION_LIST = [
  { label: 'Харків', value: 'Харків' },
  { label: 'Київ', value: 'Київ' },
  { label: 'Дніпро', value: 'Дніпро' },
  { label: 'Житомир', value: 'Житомир' },
  { label: 'Львів', value: 'Львів' },
];

export const AGE_OPTION_LIST = [
  {
    label: i18n.t('CARETAKER_LIST.MODAL.AGE.BEFORE'),
    value: 1,
  },
  {
    label: i18n.t('CARETAKER_LIST.MODAL.AGE.MIDDLE'),
    value: 2,
  },
  {
    label: i18n.t('CARETAKER_LIST.MODAL.AGE.AFTER'),
    value: 3,
  },
];

export const GENDER_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 2,
  },
];
