import React, { useState } from 'react';
import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { UserListContainer } from '../../epic/user-list';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { LayoutFooterElem } from '../../common/layout-footer';
import { HeaderContainer } from '../../epic/header';
import userIcon from '../../asset/svg/menu-app/user.svg';
import { UserListFilter } from '../../epic/user-list-filter';
import { FILTER_OPTION } from '../../epic/user-list-filter/constant';
import { MobileMenuContainer } from '../../epic/mobile-menu';

export const Page: React.FC = () => {
  const [filterOption, setFilterOption] = useState<FILTER_OPTION>();

  const setOption = (e: any) => {
    setFilterOption(e);
  };
  return (
    <PageElem>
      <LayoutAppElem>
        <HeaderContainer
          title="USER.LIST.HEADER"
          size="default"
        />
        <LayoutElem size="default">
          <LayoutSectionElem spacing={8}>
            <UserListFilter setOption={setOption} />
            <UserListContainer filterOption={filterOption}/>
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
        <DesktopMenuAppContainer />
      <LayoutFooterElem>
        <MobileMenuContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};
