import React, { useEffect, useState } from 'react';
import { Component } from './component';

export const Container: React.FC = () => {
  const [svgSrc, setSvgSrc] = useState('./assets/svg/grafic.svg');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 730) {
        setSvgSrc('./assets/img/landing/fixed-photo-mob.png');
      } else {
        setSvgSrc('./assets/img/landing/fixed-photo.png');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <Component svgSrc={svgSrc} />;
};
