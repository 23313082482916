import { LANGUAGE_ENUM, SPECIALIST_ENUM } from '../../data/user/constant';
import { TIMEZONE_ENUM, TIMEZONE_VIEW } from '../../data/user/timezone';
import { booleanConvert } from '../../lib/util/booleanConvert';
import { getYearLabel } from '../../lib/util/yearConvert';
import {
  ANIMALTYPE_VIEW,
  CATEGORY_VIEW,
  DATA_INTER,
  DATA_RAW_INTER,
  GENDER_VIEW,
  LANGUAGE_VIEW,
  METHOD_VIEW,
  PLACEMENT_VIEW,
  REQUEST_VIEW,
  SPECIALIST_VIEW,
  TRANSPORTATION_VIEW,
} from './constant';

export const convert = (data: DATA_RAW_INTER): DATA_INTER => ({
  ...data,
  methodList: data?.methodList?.map((e) => METHOD_VIEW[e]),
  requestList: data?.methodList?.map((e) => REQUEST_VIEW[e]),
  gender: GENDER_VIEW[data.gender],
  category: data?.category?.map((e) => ({
    ...e,
    category: CATEGORY_VIEW[e.category],
  })),
  timezone: TIMEZONE_VIEW[data.timezone as TIMEZONE_ENUM],
  age: getYearLabel(data.age),
  ableCompatibilityAnimal: booleanConvert(data.ableCompatibilityAnimal),
  language: data.language.map((lang) => LANGUAGE_VIEW[lang]),
  specialistWith: data.specialistWith.map((spec) => SPECIALIST_VIEW[spec]),
  workAnimalType: data.workAnimalType.map((type) => ANIMALTYPE_VIEW[type]),
  placement: data.placement.map((place) => PLACEMENT_VIEW[place]),
  transportation: data.transportation.map(
    (transport) => TRANSPORTATION_VIEW[transport],
  ),
});
