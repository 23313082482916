import React from 'react';
import styled from 'styled-components';
import { PHOTO_SRC, PHOTO_ALT } from './constant';

export const Elem: React.FC = () => {
  return (
    <PhotoContainer>
      <PhotoImage src={PHOTO_SRC} alt={PHOTO_ALT} />
    </PhotoContainer>
  );
};

const PhotoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 430px) {
    display: none;
  }
`;

const PhotoImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
