import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { useSelector } from 'react-redux';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER } from '../../data/auth/constant';
import { OrderItemContainer } from '../../epic/order-item';

export const Page: React.FC<RouteComponentProps> = () => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);

  const isLogged = () => state.logged;

  return (
    <PageElem>
      <LayoutAppElem>
        <LayoutElem size="small">
          <LayoutSectionElem spacing={5}>
            <OrderItemContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
      <LayoutFooterElem>
        <DesktopMenuAppContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};
