import React, { ReactNode } from 'react';
import { IonSkeletonText } from '@ionic/react';
import { Spacing } from '../../theme';

import styled from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { GridElem } from '../grid';
import { VALUE_OPACITY_ENUM } from '../../theme/value';
import { COLOR_ENUM } from '../../theme/color';

export const Elem: React.FC<{ single?: boolean; solo?: boolean }> = ({
  single,
  solo,
}) => {
  if (single) {
    return <SkeletonField />;
  }
  if (solo) {
    return (
      <SoloContainer>
        <SkeletonField />
      </SoloContainer>
    );
  }
  return (
    <Card spacing={4}>
      {[1, 2, 3].map(() => (
        <Container spacing={2}>
          <SkeletonField animated={true} />
        </Container>
      ))}
    </Card>
  );
};

const Card = styled(GridElem)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.SKELETON]}px;
  width: 100%;
`;

const SoloContainer = styled.div`
  margin: 20px 0;
`;

const SkeletonField = styled(IonSkeletonText)`
  height: 46px;
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
  margin: 0;
`;

const Container = styled(GridElem)`
  align-content: flex-start;
  gap: ${Spacing(3)};
`;
