export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_REGARD_MODULE_NAME';
export const MODULE_NAME_CERTIFICATE =
  'PROFILE_CARETAKER_UPDATE_CERTIFICATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export type FILE_TYPE =
  | File
  | null
  | {
      url: string;
      id: string;
    };

export const API = {
  MAIN: {
    PATCH: {
      TYPE: 'PATCH',
      URL: 'user/data-update',
    },
    GET: {
      TYPE: 'GET',
      URL: 'user/psychologist-data',
    },
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/psychologist-document',
  },
  POST_DIPLOMA: {
    TYPE: 'POST',
    URL: 'user/diploma',
  },

  POST_CERTIFICATE: {
    TYPE: 'POST',
    URL: 'user/certificate',
  },

  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string | null) => `file/${id}`,
  },
};

export enum FORM_VALUE_ENUM {
  YEAR_EXPERIENCE = 'yearExperience',
  HOURS_EXPERIENCE = 'hoursExperience',
  ABOUT_SPECIALIST = 'aboutSpecialist',
  PROFILE_DESCRIPTION = 'profileDescription',
  ABOUT_PERSON = 'aboutPerson',
  MY_PATH = 'myPath',
  WORKING_METHODS = 'workingMethods',
  MEDICATION_EXPERIENCE = 'medicationExperience',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.YEAR_EXPERIENCE]: number;
  [FORM_VALUE_ENUM.HOURS_EXPERIENCE]: number;
  [FORM_VALUE_ENUM.ABOUT_SPECIALIST]: string;
  [FORM_VALUE_ENUM.PROFILE_DESCRIPTION]: string;
  [FORM_VALUE_ENUM.ABOUT_PERSON]: string;
  [FORM_VALUE_ENUM.MY_PATH]: string;
  [FORM_VALUE_ENUM.WORKING_METHODS]: string;
  [FORM_VALUE_ENUM.MEDICATION_EXPERIENCE]: string;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
